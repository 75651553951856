import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// All website pages imported here
import Login from "../Views/Login/Login";
import Password from "../Views/Password/Password";
import Loginpassword from "../Views/Loginpassword/Loginpassword";
import Landing from "../Views/Landing/Landing";
import Business from "../Views/Business/Business";
import About from "../Views/About/About";
import Work from "../Views/Work/Work";
import Confirm from "../Views/Confirm/Confirm";
import Teamsize from "../Views/Teamsize/Teamsize";
import Businesshours from "../Views/Businesshours/Businesshours";
import Service from "../Views/Service/Service";
import Help from "../Views/Help/Help";
import Subscription from "../Views/Subscription/Subscription";
import Previoususe from "../Views/Previoususe/Previoususe";
import Set from "../Views/Set/Set";
import Dashboard from "../Views/Dashboard/Dashboard";
import Customer from "../Views/Customer/Customer";
import Customerdetail from "../Views/Customerdetail/Customerdetail";
import Catalog from "../Views/Catalog/Catalog";
import Forgotpassword from "../Views/Forgotpassword/Forgotpassword";
import Team from "../Views/Team/Team";
import Newpackage from "../Views/Newpackage/Newpackage";
import Newservice from "../Views/Newservice/Newservice";
import Addteam from "../Views/Addteam/Addteam";
import Settings from "../Views/Settings/Settings";
import Customerprofile from "../Views/CustomerProfile/Customerprofile";
import Schedule from "../Views/Schedule/Schedule";
import Addclient from "../Views/Addclient/Addclient";
import Shop from "../Views/Shop/Shop";
import RequireAuth from "../Components/RequireAuth";

const Navigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/password" element={<Password />} />
        <Route path="/loginpassword" element={<Loginpassword />} />
        <Route path="/forgot" element={<Forgotpassword />} />
        <Route
          path="/business"
          element={
            <RequireAuth>
              <Business />
            </RequireAuth>
          }
        />
        <Route
          path="/about"
          element={
            <RequireAuth>
              <About />
            </RequireAuth>
          }
        />
        <Route
          path="/work"
          element={
            <RequireAuth>
              <Work />
            </RequireAuth>
          }
        />
        <Route
          path="/confirm"
          element={
            <RequireAuth>
              <Confirm />
            </RequireAuth>
          }
        />
        <Route
          path="/teamsize"
          element={
            <RequireAuth>
              <Teamsize />
            </RequireAuth>
          }
        />
        <Route
          path="/businesshours"
          element={
            <RequireAuth>
              <Businesshours />
            </RequireAuth>
          }
        />
        <Route
          path="/service"
          element={
            <RequireAuth>
              <Service />
            </RequireAuth>
          }
        />
        <Route
          path="/help"
          element={
            <RequireAuth>
              <Help />
            </RequireAuth>
          }
        />
        <Route
          path="/previoususe"
          element={
            <RequireAuth>
              <Previoususe />
            </RequireAuth>
          }
        />
        <Route
          path="/set"
          element={
            <RequireAuth>
              <Set />
            </RequireAuth>
          }
        />
        <Route
          path="/team"
          element={
            <RequireAuth>
              <Team />
            </RequireAuth>
          }
        />
        <Route
          path="/addTeam"
          element={
            <RequireAuth>
              <Addteam />
            </RequireAuth>
          }
        />
        <Route
          path="/catalog"
          element={
            <RequireAuth>
              <Catalog />
            </RequireAuth>
          }
        />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/addClient" element={<Addclient />} />
        <Route path="/service" element={<Service />} />
        <Route path="/newservice" element={<Newservice />} />
        <Route path="/newpackage" element={<Newpackage />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/customerdetails" element={<Customerdetail />} />
        <Route path="/customerprofile" element={<Customerprofile />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
