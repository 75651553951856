import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../core/hooks/useAuth';
import React from 'react';
import { useCookies } from 'react-cookie';
import CommonCookie from './CommonCookie';
// import Unauthorized from '../views/auth/Unauthorized/Unauthorized';

// Define the propTypes interface for the RequireAuth component
interface propTypes {
  children: any;
}

// RequireAuth component definition
function RequireAuth(props: propTypes) {
  const { auth } = useAuth();
  const location = useLocation();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  // Render component based on authentication and permissions
  return cookies?.token ? (
    props.children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default RequireAuth;
