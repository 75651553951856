import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Catalog.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDropzone } from "react-dropzone";

import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import toast from "react-hot-toast";
import VendorService from "../../Services/vendor.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Catalog: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState("home");
  const [selectedImage, setSelectedImage] = useState(null);
  const [serviceList, setServiceList] = useState<any>({});
  const [categoryList, setCategoryList] = useState<any>([]);
  const vendorService = VendorService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  /* business hours */

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [webError, setWebError] = useState("");

  useEffect(() => {
    getServiceList();
  }, []);

  function addHours() {
    navigate("/service");
    // }
  }

  /* business hours */

  const handleTitleClick = (title: any) => {
    setSelectedTitle(title);
  };

  function gotoAbout() {}
  function booked() {}

  const getServiceList = async () => {
    const fd = new FormData();
    fd.append("vendor_detail_id", cookies.vendor_id);
    try {
      const response = await vendorService().getServiceList(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        setCategoryList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  return (
    <Row className="dashboard">
      <Col className="col-md-2">
        <AdminMenu />
      </Col>

      <Modal id="modalFilter" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select aria-label="Default select example">
            <option>Status</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>

          <Form.Select aria-label="Default select example">
            <option>Type</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>

          <Form.Select aria-label="Default select example">
            <option>Team Member</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>

          <Form.Select aria-label="Default select example">
            <option>Online Booking</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>

          <Form.Select aria-label="Default select example">
            <option>Commissions</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <Col className="col-md-10">
        <AdminHeader />

        <Row className="dashboardRow catalog">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h3 className="mainTitle">{t("catalogList")}</h3>
              <InputText
                placeholder={t("search")}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></InputText>
            </div>
            <div className="col-md-6">
              <ButtonGroup className="last">
                <DropdownButton
                  as={ButtonGroup}
                  title={t("createNew")}
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item eventKey="1">
                    <Link to="/newpackage">
                      <span>{t("newPackage")}</span>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2">
                    <Link to="/newService">
                      <span>{t("newService")}</span>
                    </Link>
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>

              <ButtonGroup>
                <DropdownButton
                  as={ButtonGroup}
                  title={t("options")}
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item eventKey="1">
                    <Image src={icons.updownArrows}></Image>
                    {t("setMenuOrder")}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2">
                    <Image src={icons.updownArrows}></Image>
                    {t("setBookingSequence")}
                  </Dropdown.Item>
                  <hr></hr>
                  <Dropdown.Item eventKey="3">
                    <Image src={icons.downloadPDF}></Image>
                    {t("downloadPDF")}
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>

              <Button className="filterButton" onClick={handleShow}>
                Filters <Image src={icons.filter}></Image>
              </Button>
            </div>
          </Row>
          <hr></hr>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} className="categories">
                <h4>{t("categories")}</h4>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      All Categories<span>8</span>
                    </Nav.Link>
                  </Nav.Item>
                  {categoryList.map((cat: any, ind: any) => (
                    <Nav.Item key={ind} onClick={() => setServiceList(cat)}>
                      <Nav.Link eventKey={cat.category_name}>
                        {cat.category_name}
                        <span>{cat.total_services}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content className="tabContent">
                  <Tab.Pane eventKey="first">
                    <h5 className="top">Hair & Styling</h5>
                    <Row className="serviceItems">
                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <h5>Nail</h5>
                    <Row className="serviceItems">
                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <h5>Wax</h5>
                    <Row className="serviceItems">
                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <h5>Eyebrow and Eyelashes</h5>
                    <Row className="serviceItems">
                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-md-6 main">
                        <Row>
                          <Col className="col-md-6">
                            <h3>Manicure</h3>
                            <h4>Process Time : 1hr</h4>
                          </Col>
                          <Col className="col-md-6">
                            <h6>₹65</h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey={serviceList.category_name}>
                    <h5 className="top">{serviceList.category_name}</h5>
                    <Row className="serviceItems">
                      {serviceList?.services?.map((service: any, ind: any) => (
                        <Col className="col-md-6 main" key={ind}>
                          <Row>
                            <Col className="col-md-6">
                              <h3>{service?.service_type?.name}</h3>
                              <h4>Process Time : {service?.service_time}</h4>
                            </Col>
                            <Col className="col-md-6">
                              <h6>₹{service?.charges}</h6>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </Col>
    </Row>
  );
};

export default Catalog;
