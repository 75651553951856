import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Settings.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDropzone } from "react-dropzone";

import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";
import toast from "react-hot-toast";

const Settings: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState("home");
  const [selectedImage, setSelectedImage] = useState(null);
  /* business hours */
  const [isChecked1, setIsChecked1] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);
  const [isChecked3, setIsChecked3] = useState(true);
  const [isChecked4, setIsChecked4] = useState(true);
  const [isChecked5, setIsChecked5] = useState(true);
  const [isChecked6, setIsChecked6] = useState(true);
  const [isChecked7, setIsChecked7] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [businessTime, setBusinessTime] = useState({ Monday: "10:00 - 19:00" });
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("19:00");
  const [startTimeMonday, setStartTimeMonday] = useState("10:00");
  const [endTimeMonday, setEndTimeMonday] = useState("19:00");
  const [isOpenMonday, setIsOpenMonday] = useState(false);
  const [saloon, setSaloon] = useState("");
  const [saloonError, setSaloonError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessTypeError, setbusinessTypeError] = useState("");
  const [saloonType, setSaloonType] = useState("");
  const [saloonTypeError, setSaloonTypeError] = useState("");
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [instagram, setInstagram] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [facebook, setFaceook] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitterError, setTwitterError] = useState("");
  const [youtube, setYoutube] = useState("");
  const [youtubeError, setYoutubeError] = useState("");
  const [web, setWeb] = useState("");
  const [webError, setWebError] = useState("");
  const [profileData, setProfileData] = useState<any>({});

  const handleSubmit = () => {
    // Validate start and end times
    if (startTime < endTime) {
      // Update state variables
      setStartTime(startTime);
      setEndTime(endTime);
      handleClose();
    } else {
      // Handle validation error
      console.error("Start time must be earlier than end time.");
    }
  };
  const [show, setShow] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (day: any) => {
    setSelectedDay(day); // Set the selected day
    setShow(true);
  };
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const [day, setDay] = useState<any>([]);
  const [slotType, setSlotType] = useState<any>([]);

  useEffect(() => {
    getProfileData();
  }, []);

  function addHours() {
    navigate("/service");
    // }
  }

  /* business hours */
  const handleTitleClick = (title: any) => {
    setSelectedTitle(title);
  };

  function personalInfo() {
    setSaloonError("");
    setNameError("");
    setbusinessTypeError("");
    setSaloonTypeError("");
    setGenderError("");
    setEmailError("");
    setPhoneError("");
    let isValid = true;

    if (saloon === "") {
      setSaloonError("Please enter Your Business Name");
      isValid = false;
    }
    if (name === "") {
      setNameError("Please enter Your Name");
      isValid = false;
    }
    if (businessType === "") {
      setbusinessTypeError("Please select Your Business Type");
      isValid = false;
    }
    if (saloonType === "") {
      setSaloonTypeError("Please select Your Saloon Name");
      isValid = false;
    }
    if (gender === "") {
      setGenderError("Please select Your Gender");
      isValid = false;
    }
    if (email === "") {
      setEmailError("Please enter Your Email Address");
      isValid = false;
    }
    if (phone === "") {
      setPhoneError("Please select Your Phone Number");
      isValid = false;
    }
    if (isValid == true) {
      alert("Your Details has been Submitted");
    }
  }

  function socialMedia() {
    let isValid = true;
    setInstagramError("");
    setFacebookError("");
    setTwitterError("");
    setYoutubeError("");
    setWebError("");
    if (instagram === "") {
      setInstagramError("Please enter Your Instagram URL ");
      isValid = false;
    }
    if (facebook === "") {
      setFacebookError("Please enter Your Facebook URL");
      isValid = false;
    }
    if (twitter === "") {
      setTwitterError("Please enter Your Twitter URL");
      isValid = false;
    }
    if (youtube === "") {
      setYoutubeError("Please enter Your Youtube URL");
      isValid = false;
    }
    if (web === "") {
      setWebError("Please enter Your Website URL");
      isValid = false;
    }
    if (isValid == true) {
      alert("Your Social Media URL has been Submitted");
    }
  }
  function gotoAbout() {}

  const getProfileData = async () => {
    const fd = new FormData();
    try {
      const response = await authService().getProfileData(fd);
      if (response.data.success) {
        // toast.success(response.data.message);
        // setCookie(CommonCookie.vendor_id, response.data?.data?.vendorInformation[0]?.vendor_detail_id, {
        //   path: "/",
        //   secure: true,
        //   sameSite: "lax",
        // });
        // navigate("/work");
        setDay(response?.data?.data?.vendorInformation[0]?.vendorSchedule);
        setProfileData(response?.data?.data?.vendorInformation[0]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  function postData(data: any) {
    return data
      .filter(({ onOff }: any) => onOff !== false && onOff !== undefined) // Exclude objects where onOff is false
      .map(({ day_of_week, start_time, end_time, slots }: any) => ({
        day_of_week,
        start_time,
        end_time,
        slots,
      }));
  }

  const commonDropdowns = async () => {
    try {
      const response = await authService().commonDropdowns();
      if (response.data.success) {
        toast.success(response.data.message);
        setSlotType(response.data?.slot_types);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  return (
    <Row className="dashboard">
      <Col className="col-md-2">
        <AdminMenu />
      </Col>
      <Col className="col-md-10">
        <AdminHeader />
        <Row className="dashboardRow settings">
          <div className="col-md-5">
            <h3>{t("uploadPhotos")}</h3>
            <Image src={icons.profilePhoto}></Image>
            <CustomButton
              class="uploadButton"
              ButtonTitle={t("uploadPhoto")}
              onClickButton={() => gotoAbout()}
            ></CustomButton>
            <div className="uploadFull row">
              <Image src={icons.settingsUpload}></Image>
              <h3>{t("uploadPrimary")}</h3>
            </div>
            <Row className="uploadHalf col-md-6">
              <Image src={icons.settingsUpload}></Image>
            </Row>
            <Row className="uploadHalf col-md-6">
              <Image src={icons.settingsUpload}></Image>
            </Row>
            <Row className="uploadHalf col-md-6">
              <Image src={icons.settingsUpload}></Image>
            </Row>
            <Row className="uploadHalf col-md-6 last">
              <Image src={icons.settingsUpload}></Image>
            </Row>
          </div>
          <div className="col-md-7">
            <Tabs
              defaultActiveKey="media"
              transition={false}
              id="noanim-tab-example"
              className="settingsTab"
            >
              <Tab
                eventKey="media"
                title={t("media")}
                className="mediaDetails personalDetails"
              >
                <Row>
                  <Col className="col-md-6">
                    <InputGroup className="">
                      <InputGroup.Text id="basic-addon1"><Image src={icons.url} ></Image></InputGroup.Text>
                        <Form.Control placeholder={t("addYoutubeURL")} aria-label="Username" aria-describedby="basic-addon1" />
                      </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {saloonError}
                    </small>
                  </Col>
                  <Col className="col-md-6 upload">
                    <h4><Image src={icons.settingsUpload} ></Image>{t("uploadPhotos")}</h4>
                    <small id="emailHelp" className="form-text text-danger">
                      {nameError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <InputGroup className="">
                      <InputGroup.Text id="basic-addon1"><Image src={icons.url} ></Image></InputGroup.Text>
                        <Form.Control placeholder={t("addYoutubeURL")} aria-label="Username" aria-describedby="basic-addon1" />
                      </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {saloonError}
                    </small>
                  </Col>
                  <Col className="col-md-6 upload">
                    <h4><Image src={icons.settingsUpload} ></Image>{t("uploadPhoto")}</h4>
                    <small id="emailHelp" className="form-text text-danger">
                      {nameError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 url">
                    <h5>{t("addURL")}</h5>
                    <small id="emailHelp" className="form-text text-danger">
                      {nameError}
                    </small>
                  </Col>
                  <Col className="col-md-6 url">
                    <h5>{t("addPhoto")}</h5>
                    <small id="emailHelp" className="form-text text-danger">
                      {nameError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-12">
                    <CustomButton
                      class=""
                      ButtonTitle={t("saveChanges")}
                      onClickButton={() => personalInfo()}
                    ></CustomButton>
                  </Col>
                </Row>
              </Tab>

              <Tab
                eventKey="home"
                title={t("personalDetails")}
                className="personalDetails"
              >
                <Row>
                  <Col className="col-md-6">
                    <InputText
                      placeholder={t("businessName")}
                      type="text"
                      onChange={(e) => setSaloon(e.target.value)}
                    ></InputText>
                    <small id="emailHelp" className="form-text text-danger">
                      {saloonError}
                    </small>
                  </Col>
                  <Col className="col-md-6">
                    <InputText
                      placeholder={t("name")}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    ></InputText>
                    <small id="emailHelp" className="form-text text-danger">
                      {nameError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setBusinessType(e.target.value)}
                    >
                      <option value="">{t("selectBusinessType")}</option>
                      <option value="online">{t("online")}</option>
                      <option value="offline">{t("online")}</option>
                    </Form.Select>
                    <small id="emailHelp" className="form-text text-danger">
                      {businessTypeError}
                    </small>
                  </Col>
                  <Col className="col-md-6">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setSaloonType(e.target.value)}
                    >
                      <option value="">{t("selectSaloonType")}</option>
                      <option value="online">{t("online")}</option>
                      <option value="offline">{t("online")}</option>
                    </Form.Select>
                    <small id="emailHelp" className="form-text text-danger">
                      {saloonTypeError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">{t("selectGender")}</option>
                      <option value="male">{t("male")}</option>
                      <option value="female">{t("female")}</option>
                    </Form.Select>
                    <small id="emailHelp" className="form-text text-danger">
                      {genderError}
                    </small>
                  </Col>
                  <Col className="col-md-6">
                    <InputText
                      placeholder={t("emailAddress")}
                      type="text"
                      onChange={(e) => setPhone(e.target.value)}
                    ></InputText>
                    <small id="emailHelp" className="form-text text-danger">
                      {emailError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-11">
                    <InputText placeholder="12345 67902" type="text" onChange={(e) => setEmail(e.target.value)} ></InputText>
                    <small id="emailHelp" className="form-text text-danger">
                      {phoneError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-11">
                    <textarea rows={5} className="form-control" placeholder={t("description")}></textarea> 
                    <small id="emailHelp" className="form-text text-danger">
                      {phoneError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-12">
                    <CustomButton
                      class=""
                      ButtonTitle={t("saveChanges")}
                      onClickButton={() => personalInfo()}
                    ></CustomButton>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="profile"
                title={t("bhours")}
                className="businessTab"
              >
                <Row className="formRow businessHours">
                  <Col className="col-md-6 ">
                    <Form.Check
                      type="switch"
                      checked={isChecked1}
                      onChange={() => setIsChecked1(!isChecked1)}
                      aria-label="option 1"
                      label={t("monday")}
                    />
                  </Col>
                  <Col className="col-md-6 ">
                    <h4 className="businessTime">
                      {startTime} - {endTime}{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Monday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <Row className="formRow businessHours">
                  <Col className="col-md-6 ">
                    <Form.Check
                      type="switch"
                      checked={isChecked2}
                      onChange={() => setIsChecked2(!isChecked2)}
                      aria-label="option 1"
                      label={t("tuesday")}
                    />
                  </Col>
                  <Col className="col-md-6 ">
                    <h4 className="businessTime">
                      {startTime} - {endTime}{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Tuesday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <Row className="formRow businessHours">
                  <Col className="col-md-6 ">
                    <Form.Check
                      type="switch"
                      checked={isChecked3}
                      onChange={() => setIsChecked3(!isChecked3)}
                      aria-label="option 1"
                      label={t("wednesday")}
                    />
                  </Col>
                  <Col className="col-md-6 ">
                    <h4 className="businessTime">
                      10:00 - 19:00{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Wednesday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <Row className="formRow businessHours">
                  <Col className="col-md-6 ">
                    <Form.Check
                      type="switch"
                      checked={isChecked4}
                      onChange={() => setIsChecked4(!isChecked4)}
                      aria-label="option 1"
                      label={t("thursday")}
                    />
                  </Col>
                  <Col className="col-md-6 ">
                    <h4 className="businessTime">
                      10:00 - 19:00{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Thursday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>

                <Row className="formRow businessHours">
                  <Col className="col-md-6 ">
                    <Form.Check
                      type="switch"
                      checked={isChecked5}
                      onChange={() => setIsChecked5(!isChecked5)}
                      aria-label="option 1"
                      label={t("friday")}
                    />
                  </Col>
                  <Col className="col-md-6 ">
                    <h4 className="businessTime">
                      10:00 - 19:00{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Friday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>

                <Row className="formRow businessHours">
                  <Col className="col-md-6">
                    <Form.Check
                      type="switch"
                      checked={isChecked6}
                      onChange={() => setIsChecked6(!isChecked6)}
                      aria-label="option 1"
                      label={t("saturday")}
                    />
                  </Col>
                  <Col className="col-md-6">
                    <h4 className="businessTime">
                      10:00 - 19:00{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Saturday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <Row className="formRow businessHours">
                  <Col className="col-md-6">
                    <Form.Check
                      type="switch"
                      aria-label="option 1"
                      checked={isChecked7}
                      onChange={() => setIsChecked7(!isChecked7)}
                      label={t("sunday")}
                    />
                  </Col>
                  <Col className="col-md-6">
                    <h4 className="businessTime">
                      10:00 - 19:00{" "}
                      <span>
                        {" "}
                        <Image
                          src={icons.hoursArrow}
                          onClick={() => handleShow("Sunday")}
                        />{" "}
                      </span>
                    </h4>
                  </Col>
                </Row>
                <CustomButton
                  class=""
                  ButtonTitle={t("saveChanges")}
                  onClickButton={() => gotoAbout()}
                ></CustomButton>
              </Tab>
              <Tab
                eventKey="contact"
                title={t("socialConnection")}
                className="personalDetails socialDetails"
              >
                <Row>
                  <Col className="col-md-6">
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <Image src={icons.shopInstagram}></Image>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("instalgramURL")}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {instagramError}
                    </small>
                  </Col>
                  <Col className="col-md-6">
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <Image src={icons.shopFacebook}></Image>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("facebookURL")}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {facebookError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <Image src={icons.shopTwitter}></Image>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("twitterURL")}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => setFaceook(e.target.value)}
                      />
                    </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {twitterError}
                    </small>
                  </Col>
                  <Col className="col-md-6">
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <Image src={icons.shopYoutube}></Image>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("youtubeURL")}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {youtubeError}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <Image src={icons.shopGlobe}></Image>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("websiteURL")}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => setWeb(e.target.value)}
                      />
                    </InputGroup>
                    <small id="emailHelp" className="form-text text-danger">
                      {webError}
                    </small>
                  </Col>
                  <Col className="col-md-6"></Col>
                </Row>
                <Row>
                  <Col className="col-md-12">
                    <CustomButton
                      class=""
                      ButtonTitle={t("saveChanges")}
                      onClickButton={() => socialMedia()}
                    ></CustomButton>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default Settings;
