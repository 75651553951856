import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Help.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";

const Help: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState<any>([]);
  const [help, setHelp] = useState([]);
  const location = useLocation();
  const authService = AuthService();

  const [activeIndex, setActiveIndex] = useState(null); // State to track the active span index
  const spans = ["Span 1", "Span 2", "Span 3", "Span 4", "Span 5", "Span 6"];

  const handleSpanClick = (index: any) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Set the active index to the clicked span's index
  };

  const handleTitleClick = (id: any) => {
    setSelectedTitle((prevSelectedIds: any) => {
      // Check if the ID is already selected
      const isSelected = prevSelectedIds.includes(id);

      if (isSelected) {
        // Remove ID if already selected
        return prevSelectedIds.filter((selectedId: any) => selectedId !== id);
      } else if (prevSelectedIds.length < 5) {
        // Add ID if not selected and fewer than 5 items are selected
        return [...prevSelectedIds, id];
      } else {
        // Do nothing if already selecting 5 items
        return prevSelectedIds;
      }
    });
  };

  useEffect(() => {
    getHelpInfo();
  }, []);

  const getHelpInfo = async () => {
    try {
      const response = await authService().helpInfo();
      if (response.data.success) {
        toast.success(response.data.message);
        setHelp(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const handleClick = (index: any) => {
    setIsActive(!isActive);
  };

  // function for redirect back to login screen
  function backToPassword() {
    navigate(-1);
  }

  // function for redirect to about screen
  function gotoAbout() {
    navigate("/previoususe", { state: { data: { helpIds: selectedTitle } } });
  }

  return (
    <AuthLayout>
      <Row className="help">
        <Col className="col-md-1">
          <Image
            onClick={backToPassword}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.helpBar} />
        </Col>
        <h2>{t("helpTitle")}</h2>
        <p>{t("upto5")}</p>
      </Row>
      <Row className="help">
        {help.map((cat: any, ind: any) => (
          <span
            key={ind}
            onClick={() => handleTitleClick(cat.id)}
            className={`${selectedTitle.includes(cat.id) ? "selected" : ""}`}
            // className={`${selectedTitle === cat.description ? "selected" : ""}`}
          >
            {cat.description}
          </span>
        ))}
      </Row>
      <center><CustomButton
        class="helpButton"
        ButtonTitle={t("continue")}
        onClickButton={() => gotoAbout()}
      ></CustomButton></center>
    </AuthLayout>
  );
};
export default Help;
