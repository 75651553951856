// Assuming images is an object where keys are strings and values are string paths to images.
export const images: { [key: string]: string } = {
  Logo: require('./Logo.png'),
  Chart1: require('./chart.png'),
  Chart2: require('./1.png'),
  Jane: require('./Jane.png'),
  Melisha: require('./Melisha.png'),
  Frank: require('./Frank.png'),
  Mark: require('./Mark.png'),
  John: require('./John.png'),
  David: require('./David.png'),
  Sandra: require('./Sandra.png'),
  Julia: require('./Julia.png'),
  Dane: require('./Dane.png'),
  Angela: require('./Angela.png'),
  beautySpot: require('./beautySpotRichmond.png'),
  candvBeauty: require('./candvBeauty.png'),
  vilette: require('./vilette.png'),
  serenity: require('./serenity.png'),
  davey: require('./davey.png'),
  walton: require('./walton.png'),
  beautyJoane: require('./beautyWithJoane.png'),
  bodyZone: require('./theBodyZone.png'),
  brows: require('./brows.png'),
  anna: require('./anna.png'),
  krasa: require('./krasa.png'),
  arnica: require('./arnica.png'),
  android: require('./android.png'),
  iOS: require('./iOS.png'),
  jack: require('./Jack.png'),
  download: require('./download.png'),
  shopMark:require('./shopMark.png'),
  Franklin: require('./Franklin.png'),
  Olivia: require('./Olivia.png'),
  Klara: require('./Klara.png'),
  Jolly: require('./Jolly.png'),
  Michle: require('./Michle.png'),
  Mihai: require('./Mihai.png'),
  shopAngela: require('./shopAngela.png'),
  addressMap: require('./addressMap.png'),
  shopPhoto1: require('./shopPhoto1.png'),
  shopPhoto2: require('./shopPhoto2.png'),
  shopPhoto3: require('./shopPhoto3.png'),
  shopPhoto4: require('./shopPhoto4.png'),
  imageProfile: require('./customerProfile.png')
};