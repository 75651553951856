import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Customer.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { useDropzone } from 'react-dropzone';


import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import CustomButton from "../../Components/CustomButton/CustomButton";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import InputText from "../../Components/CustomInputText/InputText";


const Customer: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState('home');
  const [selectedImage, setSelectedImage] = useState(null);

  /* business hours */
  const [webError, setWebError] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [checked, setChecked] = React.useState(false);

  const [show, setShow] = useState(false);
const [timeShow, setTimeShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  function addHours(){
    navigate('/service');
  // }
}
  function gotoAbout(){

  }
  function booked(){

  }
  function addClient(){
    navigate('/addClient');
  }
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>

      <Modal className="filterModal" show={show} >
        <Modal.Body>
          
          <Row className="">
            <Col className="col-md-10">
          <h2>{t("filter")}</h2>
          </Col>
          <Col className="col-md-2">
          <Image src={icons.close} onClick={handleClose} className="cancel" ></Image>
          </Col>
          </Row>
          <hr></hr>
          <center><Row>
          <Form.Select onChange={(e) => setStatus(e.target.value)} aria-label="Default select example">
                <option value="">{t("clientGroup")}</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
          </Row>

          <Row>
          <Form.Select onChange={(e) => setStatus(e.target.value)} aria-label="Default select example">
                <option value="">{t("gender")}</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Form.Select>
          </Row>
          
          <Row>
            <Col className="col-md-6 ">  
              <CustomButton class="cancel" ButtonTitle={t("cancel")} onClickButton={() => handleClose()} ></CustomButton>
            </Col>    
            <Col className="col-md-6 ">      
              <CustomButton class="save" ButtonTitle={t("save")} onClickButton={() => gotoAbout()} ></CustomButton>
            </Col>    
          </Row>      
          </center>  
        </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>

        <Row className="dashboardRow customer">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h3>{t("customerList")}</h3>
              <InputText placeholder={t("search")} class="search" type="text" value={search} onChange={(e) => setSearch(e.target.value)} ></InputText>    
            </div>
            <div className="col-md-6">
            
            <CustomButton class="customerButton" ButtonTitle={t("addMember")} onClickButton={() => addClient()} ></CustomButton>
          
            <ButtonGroup>
              <DropdownButton as={ButtonGroup} title={t("options")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1"><Image src={icons.logout} ></Image> {t("importClients")}</Dropdown.Item>
                <Dropdown.Item eventKey="2"><Image src={icons.merge} ></Image> {t("mergeClients")}</Dropdown.Item>
                <hr></hr>
                <Dropdown.Item eventKey="2"><Image src={icons.excel} ></Image> {t("excel")}</Dropdown.Item>
              </DropdownButton>
              
            </ButtonGroup>
            
            <Button onClick={handleShow} className="filterButton">Filters <Image src={icons.filter} ></Image></Button>
            </div>
          </Row>  
        
        <Row className="customerTable">
        <Table >
      <thead>
        <tr>
          <th><Form.Check label={t("name")}  /></th>
          <th>{t("email")}</th>
          <th>{t("phoneNumber")}</th>
          <th>{t("sales")}</th>
          <th>{t("createdAt")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>{t("jackLion")}</h2></td>
          <td><label>jack@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>Lara Ray</h2></td>
          <td><label>larar@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>Loren Jem</h2></td>
          <td><label>lorenj@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>Franklin Jobs</h2></td>
          <td><label>frankj@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>Michel Dom</h2></td>
          <td><label>micd@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
        <tr>
          <td><Form.Check label={t("")}  /><Image src={icons.jackLion}></Image><h2>Jerry</h2></td>
          <td><label>jerry@email.com</label></td>
          <td><label>+1 12345 78090</label></td>
          <td><h4>₹890.00</h4></td>
          <td><h4>29 Jul 2024</h4></td>
        </tr>
      </tbody>
    </Table>
        </Row>
        </Row>  
        </Col>
      </Row>  
  );
};

export default Customer;