import React, { FC } from "react";
import { useState, useEffect } from "react";
// Importing bootstrap and css
import { Button, Col, Form, Image, Nav, Row  } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { icons } from "../../Assets/icons";
import { useNavigate, Link } from 'react-router-dom';
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import "./AdminMenu.css";

// Define the props type for the component
/* interface CustomButtonProps {
  onClickButton: () => void; // The function to call on button click
  ButtonTitle: string; // The title of the button
  class?: string;
  ButtonIcon?: string; // The icon or image of button
} */



  

// Custom buttom for all pages
//const : FC<CustomButtonProps> = (CustomButtonProps) => {
const AdminMenu: React.FC = () => {  

  const [selectedTitle, setSelectedTitle] = useState("");
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };
  const t = useAppTranslation();
  return (
      <div className="adminMenu">
        <h2>Glam<span>9</span></h2>
        <Nav className="menu">
          <Link onClick={() => handleTitleClick('dashboard')} className={`${selectedTitle === 'dashboard' ? 'selected' : ''}`} to="/dashboard"><Image src={icons.dashboard} ></Image><span>{t("dashboard")}</span></Link>
          <Link onClick={() => handleTitleClick('customer')} className={`${selectedTitle === 'customer' ? 'selected' : ''}`} to="/customer"><Image src={icons.customerList} ></Image><span>{t("customerList")}</span></Link>
          <Link onClick={() => handleTitleClick('catalog')} className={`${selectedTitle === 'catalog' ? 'selected' : ''}`} to="/catalog"><Image src={icons.contactList} ></Image><span>{t("catalogList")}</span></Link>
          <Link onClick={() => handleTitleClick('subscription')} className={`${selectedTitle === 'subscription' ? 'selected' : ''}`} to="/subscription"><Image src={icons.paymentDetails} ></Image><span>{t("paymentDetails")}</span></Link>
          <Link onClick={() => handleTitleClick('team')} className={`${selectedTitle === 'team' ? 'selected' : ''}`} to="/team"><Image src={icons.teamMembers} ></Image><span>{t("teamMembers")}</span></Link>
          <Link onClick={() => handleTitleClick('settings')} className={`${selectedTitle === 'settings' ? 'selected' : ''}`} to="/settings"><Image src={icons.settings} ></Image><span>{t("settings")}</span></Link>
        </Nav>
      </div>
  );
};

export default AdminMenu;
