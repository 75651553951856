import { API_URLS } from "../core/apis/apis";
import useAxiosPrivate from "../core/hooks/useAxiosPrivate";
import useAxios from "../core/hooks/useAxios";

const VendorService = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();
  const services = () => {
    return {
      getTeamList: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.team.get_team_member}`, data);
      },
      getTeamScheduleList: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.team.get_member_schedule}`, data);
      },
      addTeamSchedule: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.team.store_member_schedule}`, data);
      },
      addTeamMember: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.team.store_team_member}`, data);
      },
      getServiceList: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.appointment.get_vendor_services}`, data);
      },
    };
  };
  return services;
};

export default VendorService;
