import React, { useState, useEffect } from "react";

// Imported bootstrap and css
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";

// Imported images and icons
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import AuthService from "../../Services/auth.service";
// Google and Facebook library
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";

// Language change
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";

// Navigation
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";
import toast from "react-hot-toast";

const Login: React.FC = () => {
  const t = useAppTranslation();
  const authService = AuthService();
  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState("");
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState({});
  const [errorStatus, setErrorStatus] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };
  // function for email validation and redirect to next screen after successfull validation
  function addEmail() {
    setEmailerror("");
    let isValid = true;
    if (!validateEmail(email)) {
      setEmailerror("Invalid Email! Please enter valid Email");
      isValid = false;
    }
    if (email === "") {
      setEmailerror("Email should not be blank");
      isValid = false;
    }

    if (isValid) {
      checkUser(email);
      // navigate("/password");
    }
  }

  const checkUser = async (email: any) => {
    const fd = new FormData();
    fd.append("email", email);
    try {
      const response = await authService().checkUser(fd);
      setLoginData({ email: email });
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/password", { state: { loginData: { email: email } } });
      }else{
        toast.success(response.data.message);
        navigate("/loginpassword", { state: { loginData: { email: email } } });
      }
      setMessage(response.data.success);
    } catch (error: any) {
      setErrorStatus(true);
      setMessage(error.response.data.message);
    }
  };

  // Login with google
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse),
  });

  useEffect(() => {
    switch (Number(cookies.profile) + 1) {
      case 1:
        return navigate("/business");
      case 2:
        return navigate("/business");
      case 3:
        return navigate("/work");
      case 4:
        return navigate("/work");
      case 5:
        return navigate("/teamsize");
      case 6:
        return navigate("/teamsize");
      case 7:
        return navigate("/service");
      case 8:
        return navigate("/help");
      case 9:
        return navigate("/help");
      case 10:
        return navigate("/dashboard");
      default:
        return navigate("/");
    }
  }, [])
  

  return (
    <AuthLayout>
      <Form className="frmLogin">
      <h4 className="logoMobile">Glam<span>9</span></h4>  
      <h1 className="loginTitle">{t("Login")}</h1>
      <center><h2>{t("enterEmail")}</h2></center>
        
          <InputText placeholder={t("emailAddress")} value={email} type="email" onChange={(e) => setEmail(e.target.value)} ></InputText>
          <small id="emailHelp" className="form-text text-danger">
            {emailerror}
          </small>
          <CustomButton class="email" ButtonTitle={t("continueWithEmail")} onClickButton={() => addEmail()} ></CustomButton>
        
      
      <center>
        <Row className="seperator">
          <Col className="col-md-5">
            <hr />
          </Col>
          <Col className="col-md-2">{t("or")}</Col>
          <Col className="col-md-5">
            <hr />
          </Col>
        </Row>
      </center>
      <Row>
        <Button className="socialBtn google" onClick={() => googleLogin()}>
          <Image src={icons.google} />
          <h3>{t("continueWithGoogle")}</h3>
        </Button>
        <FacebookLogin
          appId="1088597931155576" // Replace with your Facebook app ID
          onSuccess={(response) => {
            console.log("Login Success!", response);
          }}
          onFail={(error) => {
            console.log("Login Failed!", error);
          }}
          onProfileSuccess={(response) =>
            console.log("Facebook profile success:", response)
          }
          render={({ onClick }) => (
            <Button className="google socialBtn" onClick={onClick}>
              <Image src={icons.facebook} />
              <h3>{t("continueWithFacebook")}</h3>
            </Button>
          )}
        />
      </Row>
      </Form>
    </AuthLayout>
  );
};
export default Login;
