import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Password.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";
import CommonCookie from "../../Components/CommonCookie";
import { useCookies } from "react-cookie";

const Password: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [passworderror, setPassworderror] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);
  const authService = AuthService();

  
  const [hasLetter, setHasLetter] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [isLongEnough, setIsLongEnough] = useState(false);

  const handlePasswordChange = (event:any) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    // Check if the password meets the  criteria
    setHasLetter(newPassword.match(/[a-zA-Z]/));
    setHasDigit(newPassword.match(/[0-9]/));
    setIsLongEnough(newPassword.length >= 8);
  };

  const getTextColor = (condition:any) => {
    return condition ? 'green' : 'black';
  };

  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  // function for go back to login page
  function backLogin() {
    navigate(-1);
  }

  useEffect(() => {
    if (location.state === null) {
      navigate("/");
    }
    setLoginData(location.state?.loginData);
    switch (Number(cookies.profile) + 1) {
      case 1:
        return navigate("/business");
      case 2:
        return navigate("/business");
      case 3:
        return navigate("/work");
      case 4:
        return navigate("/work");
      case 5:
        return navigate("/teamsize");
      case 6:
        return navigate("/teamsize");
      case 7:
        return navigate("/service");
      case 8:
        return navigate("/help");
      case 9:
        return navigate("/help");
      case 10:
        return navigate("/dashboard");
      default:
        return navigate("/password");
    }
  }, []);

  // function for password validation and redirect to business page after successsfully validation
  function checkPassword() {
    setPassworderror("");
    let isValid = true;
    if (password === "") {
      setPassworderror("Password should not be blank");
      isValid = false;
    } else {
      register();
    }
  }
  // function for view password
  function showpassword() {
    setType(type === "password" ? "text" : "password");
  }

  const register = async () => {
    const fd = new FormData();
    fd.append("email", loginData.email || '');
    fd.append("password", password);
    try {
      const response = await authService().register(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        setCookie(CommonCookie.token, response.data?.data?.token, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        setCookie(CommonCookie.profile, response.data?.data?.profile_flow_screen, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        setCookie(CommonCookie.vendor_id, response.data?.data?.vendor_detail_id, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        navigate("/business", {
          state: { loginData: { email: loginData.email, password: password } },
        });
      } else {
        toast.error(response.data.message);
      }
      setLoginData((prevState: any) => ({
        ...prevState,
        password: password,
      }));
      setMessage(response.data.success);
    } catch (error: any) {
      setErrorStatus(true);
      setMessage(error);
      toast.error(error?.data?.message || 'Oops, Something went wrong.');
    }
  };

  return (
    <AuthLayout>
      <Row className="topBar">  
        <Col className="col-md-1">
          <Image onClick={backLogin} className="backButton" src={icons.back} />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.pwdBar} />
        </Col>
      </Row>  
      <Row className="password">  
        <h1>{t("password")}</h1>
        <h6>{t("enterPassword")}</h6>
      </Row>
      <Row className="pwdSection password">
        <InputGroup>
          <Form.Control
            type={type}
            placeholder={t("password")}
            aria-label={t("password")}
            aria-describedby="basic-addon2"
            onChange={handlePasswordChange}
          />
          <InputGroup.Text id="basic-addon2" onClick={showpassword}>
            {t("show")}
          </InputGroup.Text>
        </InputGroup>
        <small id="emailHelp" className="form-text text-danger">
          {passworderror}
        </small>
        <h4>{t("passwordMustContain")}</h4>

        <small id="emailHelp" className="form-text text-danger">
      
        
        <p style={{ color: getTextColor(hasLetter) }}>
        <Image src={icons.check}></Image> &nbsp; {t("atLeastOneLetter")} 
        </p>
        <p style={{ color: getTextColor(hasDigit) }}>
            <Image src={icons.check}></Image> &nbsp; {t("atLeastOneDigit")}
          </p>
          <p style={{ color: getTextColor(isLongEnough) }}>
            <Image src={icons.check}></Image> &nbsp; {t("charactersOrMore")}
          </p>	
      
    </small>
        
        <CustomButton
          ButtonTitle={t("continue")}
          onClickButton={() => checkPassword()}
        ></CustomButton>
      </Row>
    </AuthLayout>
  );
};

export default Password;
