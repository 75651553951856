import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Set.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate } from 'react-router-dom';

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";


const Set: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  
  // function for redirect to next screen
  function gotoNext(){
    navigate('/dashboard');
  }
  return (
    <Row className="set">
      <div className="setContent">
        <h4>Glam<span>9</span></h4>
        <h1>{t('allSet')}</h1>
        <h2>{t('welcome')}</h2>
        <p>{t('setParagraph1')}</p>
        <p>{t('setParagraph2')}</p>
        <CustomButton class="setButton" ButtonTitle={t("continue")} onClickButton={() => gotoNext()} ></CustomButton>
      </div>
    </Row>
  );
};

export default Set;