import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Addclient.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDropzone } from 'react-dropzone';


import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";


const Addclient: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState('home');
  const [selectedImage, setSelectedImage] = useState(null);

  const [first, setFirst] = useState("");
  const [firstError, setFirstError] = useState("");
  const [last, setLast] = useState("");
  const [lastError, setLastError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [birth, setBirth] = useState("");
  const [birthError, setBirthError] = useState("");
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [pronounce, setPronounce] = useState("");
  const [pronounceError, setPronounceError] = useState("");
  const [client, setClient] = useState("");
  const [clientError, setClientError] = useState("");
  const [lang, setLang] = useState("");
  const [occupation, setOccupation] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [addPhone, setAddPhone] = useState("");

  /* business hours */

  const [webError, setWebError] = useState("");
  function addHours(){
    navigate('/service');
  // }
}
/* business hours */
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };
  function gotoAbout(){
  }
  function gotoCustomer(){
    navigate('/customer');
  }
  function booked(){
  }
  function saveData(){
    var isvalid = true;
    setFirstError("");
    setLastError("");
    setEmailError("");
    setBirthError("");
    setPhoneError("");
    setGenderError("");
    setPronounceError("");
    setClientError("");
    if(first == ""){
      setFirstError("Please enter first name");
      isvalid = false;
    }
    if(last == ""){
      setLastError("Please enter Last name");
      isvalid = false;
    }
    if(email == ""){
      setEmailError("Please enter your email");
      isvalid = false;
    }
    if(phone == ""){
      setPhoneError("Please enter your Phone Number");
      isvalid = false;
    }
    if(birth == ""){
      setBirthError("Please select your Birth date");
      isvalid = false;
    }
    if(gender == ""){
      setGenderError("Please select your Gender");
      isvalid = false;
    }
    if(isvalid == true){
      alert("New Client Details Added Successfully");
    }
  }
    
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>
        <Row className="dashboardRow newClient">
          <Row>
            <Col className="col-md-6">
            <h2>{t("addNewClient")}</h2>
            </Col>  
            <Col className="col-md-6">
            <CustomButton class="save" ButtonTitle={t("save")} onClickButton={() => saveData()} ></CustomButton>
            <CustomButton class="cancel" ButtonTitle={t("close")} onClickButton={() => gotoCustomer()} ></CustomButton>
            </Col>
          </Row>  
          <hr></hr>        
          <div className="col-md-7">
            <div className="row profile">
              <Image src={icons.profilePhoto} ></Image>  
              <CustomButton class="uploadButton" ButtonTitle={t("uploadPhoto")} onClickButton={() => gotoAbout()} ></CustomButton>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputText placeholder={t("firstName")} type="text" onChange={(e) => setFirst(e.target.value)} ></InputText>  
                <small className="form-text text-danger">{firstError}</small>
              </div>
              <div className="col-md-6">
                <InputText placeholder={t("lastName")} type="text" onChange={(e) => setLast(e.target.value)} ></InputText>  
                <small className="form-text text-danger">{lastError}</small>
              </div>
          </div>  
          <div className="row">
            <div className="col-md-6">
              <InputText placeholder={t("email")} type="text" onChange={(e) => setEmail(e.target.value)} ></InputText>  
              <small className="form-text text-danger">{emailError}</small>
            </div>
            <div className="col-md-6">
              <InputText placeholder={t("phone")} type="text" onChange={(e) => setPhone(e.target.value)} ></InputText>  
              <small className="form-text text-danger">{phoneError}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <InputText placeholder={t("dateofBirth")} type="date" onChange={(e) => setBirth(e.target.value)} ></InputText>  
            <small className="form-text text-danger">{birthError}</small>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <Form.Select aria-label="Default select example" onChange={(e) => setGender(e.target.value)}>
                <option>{t("gender")}</option>
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </Form.Select>
              <small className="form-text text-danger">{genderError}</small>
            </div>
            <div className="col-md-6">
              <InputText placeholder={t("pronounce")} type="text" onChange={(e) => setPronounce(e.target.value)} ></InputText>  
              
            </div>
          </div>
          <div className="row">
            <h3>{t("additionalInfo")}</h3>
            <div className="col-md-6">
            <Form.Select aria-label="Default select example" onChange={(e) => setLang(e.target.value)}>
                <option>{t("preferredLanguage")}</option>
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </Form.Select>
            </div>
            <div className="col-md-6">
            <Form.Select className="clientSource" aria-label="Default select example" onChange={(e) => setClient(e.target.value)}>
                <option>{t("clientSource")}</option>
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </Form.Select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <InputText placeholder={t("occupation")} type="text" onChange={(e) => setOccupation(e.target.value)} ></InputText>  
            </div>
            <div className="col-md-6">
            <Form.Select aria-label="Default select example">
                <option>{t("country")}</option>
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </Form.Select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <InputText placeholder={t("email")} type="text" onChange={(e) => setAddEmail(e.target.value)} ></InputText>  
            </div>
            <div className="col-md-6">
            <InputText placeholder={t("phone")} type="text" onChange={(e) => setAddPhone(e.target.value)} ></InputText>  
            </div>
          </div>
          <Row>
              <h3>{t("address")}</h3>
              <h6>Add New Address +</h6>
            </Row>    
              </div>
              
          
            </Row>  
        </Col>
      </Row>  
  );
};

export default Addclient;