import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Addteam.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link, Navigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import { useDropzone } from "react-dropzone";

import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import VendorService from "../../Services/vendor.service";
import CommonCookie from "../../Components/CommonCookie";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Addteam: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState("home");
  const [selectedImage, setSelectedImage] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emergency, setEmergency] = useState("");
  const [emergencyError, setEmergencyError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");

  const [joinDate, setJoinDate] = useState<string>("");
  const [joinDateError, setJoinDateError] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const [teamMemberId, setTeamMemberId] = useState("");
  const [teamMemberIdError, setTeamMemberIdError] = useState("");
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  /* business hours */

  const [webError, setWebError] = useState("");

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const [checked, setChecked] = useState(false);
  const vendorService = VendorService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const location = useLocation();
  const [imageFile, setImageFile] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<any>(icons.profilePhoto);
  const fileInputRef = useRef<any>(null);

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function addHours() {
    navigate("/service");
    // }
  }

  function saveTeamMember() {
    setFirstNameError("");
    setLastNameError("");
    setMobileError("");
    setEmergencyError("");
    setEmailError("");
    setJobTitleError("");
    setJoinDateError("");
    setEndDateError("");
    setNoteError("");
    setTeamMemberIdError("");
    let isValid = true;
    if (firstName == "") {
      setFirstNameError("please enter your first name");
      isValid = false;
    }
    if (lastName == "") {
      setLastNameError("please enter your last name");
      isValid = false;
    }
    if (mobile == "") {
      setMobileError("please enter your Mobile Number");
      isValid = false;
    }
    if (email == "") {
      setEmailError("please enter your Email Address");
      isValid = false;
    }
    if (jobTitle == "") {
      setJobTitleError("please enter your Job Title");
      isValid = false;
    }
    if (isValid) {
      storeTeamMember();
    }
  }
  const storeTeamMember = async () => {
    const fd = new FormData();
    fd.append("first_name", firstName || "");
    fd.append("last_name", lastName || "");
    fd.append("email", email || "");
    fd.append("country_code", "91" || "");
    fd.append("mobile", mobile || "");
    fd.append("emergency_mobile_country_code", "91" || "");
    fd.append("emergency_mobile", emergency || "");
    fd.append("profile_image", imageFile || "");
    fd.append("job_title", jobTitle || "");
    fd.append("joining_date", joinDate || "");
    fd.append("end_date", endDate || "");
    fd.append("notes", note || "");
    fd.append("vendor_detail_id", cookies.vendor_id || "");
    try {
      const response = await vendorService().addTeamMember(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/team")
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Oops, Something went wrong.");
    }
  };
  function goBack() {
    navigate("/team")
  }

  return (
    <Row className="dashboard">
      <Col className="col-md-2">
        <AdminMenu />
      </Col>

      <Col className="col-md-10">
        <AdminHeader />

        <Row className="dashboardRow addTeam">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h2>
                <Image src={icons.leftArrow} onClick={()=>goBack()}></Image>
                {t("addMemberProfile")}
              </h2>
            </div>
          </Row>
          <Row className="col-md-12 upload">
            <Image src={imageUrl} alt="Uploaded Image" />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
              <Button
                className="uploadButton"
                onClick={() =>
                  fileInputRef.current && fileInputRef.current.click()
                }
              >
                {t("uploadPhoto")}
              </Button>
          </Row>
          <Col className="col-md-7">
            <div className="row">
              <Col className="col-md-6">
                <InputText
                  placeholder={t("firstName")}
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">
                  {firstNameError}
                </small>
              </Col>
              <Col className="col-md-6">
                <InputText
                  placeholder={t("lastName")}
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{lastNameError}</small>
              </Col>
            </div>

            <div className="row">
              <Col className="col-md-6">
                <InputText
                  placeholder={t("mobileNumber")}
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{mobileError}</small>
              </Col>
              <Col className="col-md-6">
                <InputText
                  placeholder={t("emergencyContact")}
                  type="text"
                  value={emergency}
                  onChange={(e) => setEmergency(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">
                  {emergencyError}
                </small>
              </Col>
            </div>

            <div className="row">
              <Col className="col-md-6">
                <InputText
                  placeholder={t("emailAddress")}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{emailError}</small>
              </Col>
              <Col className="col-md-6">
                <InputText
                  placeholder={t("jobTitle")}
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{jobTitleError}</small>
              </Col>
            </div>

            <div className="row">
              <Col className="col-md-6">
                <InputText
                  placeholder="Joinig Date"
                  type="date"
                  value={joinDate}
                  onChange={(e) => setJoinDate(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{joinDateError}</small>
              </Col>
              <Col className="col-md-6">
                <InputText
                  placeholder="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{endDateError}</small>
              </Col>
            </div>

            <div className="row">
              <Col className="col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder={t("addNote")}
                    as="textarea"
                    rows={3}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <small className="form-text text-danger">
                    {teamMemberIdError}
                  </small>
                </Form.Group>
              </Col>
            </div>

            <div className="row">
              <Col className="col-md-6">
                <InputText
                  placeholder={t("teamMemberId")}
                  type="text"
                  value={teamMemberId}
                  onChange={(e) => setTeamMemberId(e.target.value)}
                ></InputText>
                <small className="form-text text-danger">{noteError}</small>
              </Col>
              <Col className="col-md-6">
                <CustomButton
                  class="addButton"
                  ButtonTitle={t("addMemberB")}
                  onClickButton={() => saveTeamMember()}
                ></CustomButton>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Addteam;
