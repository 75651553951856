import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Schedule.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { useDropzone } from 'react-dropzone';


import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";


const Schedule: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  /* business hours */
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [checked, setChecked] = React.useState(false);
/* business hours */

const [show, setShow] = useState(false);
const [timeShow, setTimeShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


const timeHandleClose = () => setTimeShow(false);
const timeHandleShow = () => setTimeShow(true);

const [isDropdownVisible, setIsDropdownVisible] = useState(false);

const handleSwitchChange = () => {
  setIsDropdownVisible(!isDropdownVisible);
};

function addServiceInfo(){

  }

  function gotoAbout(){

  }

  function gotoAddTeam(){
    navigate('/addTeam');
  }
    return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>


      
    

        <Row className="dashboardRow schedule">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h3>{t("scheduleShifts")}</h3>
            </div>
            <div className="col-md-6">
            <CustomButton class="customerButton" ButtonTitle={t("today")} onClickButton={() => gotoAbout()} ></CustomButton>
            <div className="calenderButton" > {'<'} 29 Jul - 4 Aug, 2024 { '>' }</div>
            <ButtonGroup>
              <DropdownButton as={ButtonGroup} title={t("add")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
            <ButtonGroup>
              <DropdownButton as={ButtonGroup} className="opinion" title={t("opinion")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
            </div>
          </Row>  
        <Row className="scheduleTable">
        <Table >
      <thead>
        <tr>
          <th><h4>{t("teamMembers")}</h4></th>
          <th><center><h4>29</h4><span>Mon</span></center></th>
          <th><center><h4>30</h4><span>Tue</span></center></th>
          <th><center><h4>1</h4><span>Wed</span></center></th>
          <th><center><h4>2</h4><span>Thu</span></center></th>
          <th><center><h4>3</h4><span>Fri</span></center></th>
          <th><center><h4>4</h4><span>Sat</span></center></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Image className="profilePhoto" src={icons.jackLion}></Image><h2>{t("jackLion")}</h2> <Image className="edit" src={icons.edit} ></Image></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
        </tr>
        <tr>
          <td><Image className="profilePhoto" src={icons.jackLion}></Image><h2>Lara Ray</h2> <Image className="edit" src={icons.edit} ></Image></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
          <td><center><span>10am - 7am</span></center></td>
        </tr>
      </tbody>
    </Table>
        </Row>
        </Row>  
        </Col>
      </Row>  
  );
};
export default Schedule;