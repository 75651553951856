import React, { FC } from "react";
// Importing bootstrap and css
import { Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CustomButton.css";

// Define the props type for the component
interface CustomButtonProps {
  onClickButton: () => void; // The function to call on button click
  ButtonTitle: string; // The title of the button
  class?: string;
  ButtonIcon?: string; // The icon or image of button
}

// Custom buttom for all pages
const CustomButton: FC<CustomButtonProps> = (CustomButtonProps) => {
  return (
    <Button
      className={CustomButtonProps.class}
     //  className="google socialBtn"
      onClick={CustomButtonProps.onClickButton}
    >
      {CustomButtonProps.ButtonIcon ? (
        <Image src={CustomButtonProps.ButtonIcon} />
      ) : null}
      <h3>{CustomButtonProps.ButtonTitle}</h3>
    </Button>
  );
};

export default CustomButton;
