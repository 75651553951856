import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Business.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Business: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState<any>([]);
  const [businessError, setBusinessError] = useState("");
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const handleTitleClick = (title: any) => {
    // setSelectedTitle(title);
    setSelectedTitle((prevSelectedItems: any) => {
      const isSelected = prevSelectedItems.some(
        (selected: any) => selected.id === title.id
      );

      if (isSelected) {
        // Remove item if already selected
        return prevSelectedItems.filter(
          (selected: any) => selected.id !== title.id
        );
      } else if (prevSelectedItems.length < 5) {
        // Add item if not selected and less than 5 items are selected
        return [...prevSelectedItems, title];
      } else {
        // Do nothing if already selecting 5 items
        return prevSelectedItems;
      }
    });
  };

  const getCategories = async () => {
    try {
      const response = await authService().getCategories();
      if (response.data.success) {
        // toast.success(response.data.message);
        setCategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const firstSixArray = category.slice(0, 6);
  const lastSixArrays = category.slice(7, 12);
  // function for redirect back to login screen
  function backToPassword() {
    navigate(-1);
  }

  // function for redirect to about screen
  function gotoAbout() {
    setBusinessError("");
    if (selectedTitle.length) {
      navigate("/about", {
        state: {
          loginData: {
            catId: selectedTitle.slice(0, 5).map((item: any) => item.id),
          },
        },
      });
    } else {
      setBusinessError("Please select any Category");
    }
    return false;
  }

  return (
    <AuthLayout>
      <Row className="topBar">
        <Col className="col-md-1">
          <Image
            onClick={backToPassword}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-10">
          <Image className="progressBar" src={icons.businessBar} />
        </Col>
      </Row>
      <Row className="business">
        <center>
          <h2>{t("whatBusiness")}</h2>
          <h6>{t("selectCategory")}</h6>
        </center>
      </Row>

      <Row className="Mobile business">
        {firstSixArray.map((cat: any, ind: any) => (
          <Col className="col-md-4" key={ind}>
            <div
              key={ind}
              onClick={() => handleTitleClick(cat)}
              className={`imgBorder ${
                selectedTitle.some((item: any) => item.id === cat.id)
                  ? "selected " + cat.id
                  : ""
              }`}
            >
              <Image src={icons.nailSaloon} />
            </div>
            <h4>{cat.name}</h4>
          </Col>
        ))}
      </Row>
      <Row className="Mobile business">
        <center>
          <p>{t("otherCategories")}</p>
        </center>
      </Row>
      <Row className="businessCategory Mobile business">
        <Col className="col-md-12">
          {lastSixArrays.map((cat: any) => (
            <span
              key={cat.id}
              onClick={() => handleTitleClick(cat)}
              className={`${
                selectedTitle.some((item: any) => item.id === cat.id)
                  ? "selected " + cat.id
                  : ""
              }`}
            >
              {cat.name}
            </span>
          ))}
        </Col>
      </Row>
      <small className="form-text text-danger business mt-4">
        {businessError}
      </small>
      <Row className="businessCategory Mobile business">
        <center><CustomButton
          class="businessButton"
          ButtonTitle={t("continue")}
          onClickButton={() => gotoAbout()}
        ></CustomButton></center>
      </Row>
    </AuthLayout>
  );
};
export default Business;
