import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Teamsize.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Teamsize: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [teamSize, setTeamSize] = useState("");
  const [teamSizeError, setTeamSizeError] = useState("");

  const [team, setTeam] = useState([]);
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const getTeamInfo = async () => {
    try {
      const response = await authService().teamInfo();
      if (response.data.success) {
        toast.success(response.data.message);
        setTeam(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  useEffect(() => {
    getTeamInfo();
  }, []);

  // function for go back to about page
  function backToAbout() {
    navigate(-1);
  }

  // function for checkbox validation and move to next screen after successfull validation
  function addWorkInfo() {
    let isValid = true;
    setTeamSizeError("");
    if (teamSize == "") {
      setTeamSizeError("Please Select Any One Option");
      isValid = false;
    }
    if (isValid == true) {
      navigate("/businesshours", { state: { teamData: { size: teamSize } } });
    }
  }

  return (
    <AuthLayout>
      <Row className="teamSize">
        <Col className="col-md-1">
          <Image
            onClick={backToAbout}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.teamBar} />
        </Col>
        <center>
          <h2>{t("teamSize")}</h2>
        </center>
      </Row>

      {team.map((cat: any, ind: any) => (
        <Row className="" key={ind}>
          <Col className="col-md-12">
            <Form.Check
              type="radio"
              className="teamSize"
              name="teamSize"
              aria-label="option 1"
              value={cat.id}
              onChange={(e) => setTeamSize(e.target.value)}
              label={cat.description}
            />
          </Col>
        </Row>
      ))}
      <small className="form-text text-danger mt-4">{teamSizeError}</small>
      <CustomButton
        class="teamButton"
        ButtonTitle={t("continue")}
        onClickButton={() => addWorkInfo()}
      ></CustomButton>
    </AuthLayout>
  );
};

export default Teamsize;
