import React, { FC } from "react";

// Importing bootstrap and css
import { Form, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Define the props type for the component
interface CustomTextInputProps {
  onChange: (e?: any) => void; // The function to call on change text
  placeholder: string; // The placeholder of the input
  type?: string; // The type of input
  value?: string;
  ariaLabel?: string;
  class?: string;
  ariaDescribedby?: string;
  onClickShow?: () => void;
  showTitle?: string;
}

// Custom text inputs for all pages
const InputText: FC<CustomTextInputProps> = (CustomTextInputProps) => {
  return (
    <InputGroup className="">
      <Form.Control
        type={CustomTextInputProps.type}
        placeholder={CustomTextInputProps.placeholder}
        aria-label={CustomTextInputProps.ariaLabel}
        aria-describedby={CustomTextInputProps.ariaDescribedby}
        value={CustomTextInputProps.value}
        className={CustomTextInputProps.class}
        onChange={CustomTextInputProps.onChange}
      />

      {CustomTextInputProps.showTitle ? (
        <InputGroup.Text
          id="basic-addon2"
          onClick={CustomTextInputProps.onClickShow}
        >
          {CustomTextInputProps.showTitle}
        </InputGroup.Text>
      ) : null}
    </InputGroup>
  );
};

export default InputText;
