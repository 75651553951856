import React, { FC, ReactNode } from "react";

// Imported bootstrap and css
import { Col, Row, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Imported images and icons
import { images } from "../../Assets/images";

interface AuthLayoutProps {
  children: ReactNode; // Allow children to be passed into the layout
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Row>
      <Col className="loginContent col-md-6 col-sm-12">
        <div className="loginForm" id="loginForm">
          {children}
        </div>
      </Col>
      <Col className="logo col-md-6 col-sm-12">
        <Image src={images.Logo} />
      </Col>
    </Row>
  );
};

export default AuthLayout;