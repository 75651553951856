import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Forgotpassword.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from 'react-router-dom';


import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import toast from "react-hot-toast";
import AuthService from "../../Services/auth.service";

const Forgotpassword: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [emailerror, setEmailerror] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("password");
  const authService = AuthService();
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);

  // function for go back to login page
  function backLogin() {
    navigate("/");
  }

  // function for password validation and redirect to business page after successsfully validation
  function checkPassword() {
    setEmailerror("");
    let isValid = true;
    if (email === "") {
      setEmailerror("Email should not be blank");
      isValid = false;
    } else {
      forgetPass();
    } 
    
  }
  // function for view password
  function showpassword() {
    setType(type === "password" ? "text" : "password");
  }

  const forgetPass = async () => {
    const fd = new FormData();
    fd.append("email", email || '');
    try {
      const response = await authService().forgotPassword(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
      setMessage(response.data.success);
    } catch (error: any) {
      setErrorStatus(true);
      toast.error(error?.data?.message || 'Oops, Something went wrong.');
    }
  };

  return (
    <AuthLayout>
      <Row className="forgot">
        <Col className="col-md-1">
          <Image onClick={backLogin} className="backButton" src={icons.back} />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.forgotBar} />
        </Col>
        <h2>{t("forgotPassword")}</h2>
        <h6>{t("forgotDesc")}</h6>
      </Row>
      <Row className="pwdSection forgot">
          <InputText placeholder={t("emailAddress")} type="text" value={email} onChange={(e) => setEmail(e.target.value)} ></InputText>        
        <small id="emailHelp" className="form-text text-danger">
          {emailerror}
        </small>
        <CustomButton class="Button" ButtonTitle={t("resetPassword")} onClickButton={() => checkPassword()} ></CustomButton>
      </Row>
    </AuthLayout>
  );
};

export default Forgotpassword;