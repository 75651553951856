import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Customerprofile.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDropzone } from 'react-dropzone';


import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";


const Customerprofile: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);

  /* business hours */


  
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  

  function addHours(){
    navigate('/service');
  // }
}

/* business hours */
  
  
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };

  
  function gotoAbout(){

  }
  function booked(){

  }
    
  return (
    <Row className="dashboard customerProfile">
      
      <Col className="col-md-2">
        <AdminMenu />
      </Col>

      <Modal className="profileModal" show={show} >
        <Modal.Body>
          
          <Row className="">
            <Col className="col-md-10">
          <h2>{t("editProfile")} </h2>
          </Col>
          <Col className="col-md-2">
           <Image src={icons.close} onClick={handleClose} className="cancel" ></Image> 
          </Col>
          </Row>
          <hr></hr>
          <center><Row>
          <Image src={images.imageProfile} className="profile" />
          {/* <Image src={icons.camera} className="camera" /> */}
          </Row>
          
          <Row >
            
              <InputText placeholder={t("firstName")} class="first" type="text" onChange={(e) => setFirst(e.target.value)} ></InputText>
              <InputText placeholder={t("lastName")} type="text" onChange={(e) => setLast(e.target.value)} ></InputText>
              <textarea placeholder={t("about")} className="form-control" onChange={(e) => setLast(e.target.value)}></textarea>
              <CustomButton ButtonTitle={t("saveChanges")} onClickButton={() => booked()} ></CustomButton>

          </Row>
          </center>  
        </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>

      <Col className="col-md-10">
      < AdminHeader/>
        
        

        <Row className="dashboardRow customerProfile">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} className="left">
        <Row>
          <div className="col-md-3"><Image className="leftArrow" src={icons.leftArrow}></Image></div>
          <div className="col-md-6">
            <center><Image className="profilePhoto" src={icons.micheDom}></Image>
            <h4>Michle dom</h4></center>
          </div>
          <div className="col-md-3"><Image className="edit" src={icons.edit}></Image></div>
          </Row>
        <Row>
        <Row className="userInfo">
              <h5>Joined August 2024</h5>
              <h5>India</h5>
              <h5>Reviews</h5>
            </Row>
        </Row>  
        </Col>
        <Col sm={9} className="right">
          
            
              <Row className="aboutTitle">
                <h5 className="top">{t("aboutMe")}</h5>
              </Row>
                <Row className="aboutDesc">
                  <h6 onClick={handleShow}>{t("addDescription")}</h6>
                </Row> 
        </Col>
      </Row>
    </Tab.Container>
          
        </Row>  
        </Col>
      </Row>  
  );
};

export default Customerprofile;