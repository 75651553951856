import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Service.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import toast from "react-hot-toast";
import AuthService from "../../Services/auth.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Service: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [serviceName, setServiceName] = useState("");
  const [serviceError, setServiceNameError] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [serviceList, setServiceList] = useState<any>([]);
  const [serviceTypeList, setServiceTypeList] = useState<any>([]);
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [serviceCategoryList, setServiceCategoryList] = useState<any>([]);
  const [serviceCategoryError, setServiceCategoryError] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [serviceHourError, setServiceHourError] = useState("");
  const [serviceMinute, setServiceMinute] = useState("");
  const [serviceMinuteError, setServiceMinuteError] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [servicePriceError, setServicePriceError] = useState("");
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  // function for go back to about page
  function backToAbout() {
    navigate(-1);
  }

  const [show, setShow] = useState(false);
  const [selectedCat, setSelectedCat] = useState<any>();

  const [editshow, setEditShow] = useState(false);
  const editClose = () => setEditShow(false);
  const editOpen = (service:any) => {
    setSelectedCat(service);
    setEditShow(true);
  };

  const [service, setService] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getServiceCat();
  };

  // function for checkbox validation and move to next screen after successfull validation

  useEffect(() => {
    defaultService(cookies.vendor_id);
  }, []);

  function goToHelp() {
    navigate("/help");
  }

  const getServiceCat = async () => {
    try {
      const response = await authService().getServiceCat();
      if (response.data.success) {
        toast.success(response.data.message);
        setServiceCategoryList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const getServiceType = async (serviceCategory: any) => {
    try {
      const response = await authService().getServiceType(serviceCategory);
      if (response.data.success) {
        toast.success(response.data.message);
        setServiceTypeList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  function addServiceInfo() {
    let isValid = true;
    setServiceNameError("");
    setServiceTypeError("");
    setServiceCategoryError("");
    setServiceHourError("");
    setServiceMinuteError("");
    setServicePriceError("");

    if (serviceName == "") {
      setServiceNameError("Please Enter Service");
      isValid = false;
    }

    if (serviceType == "") {
      setServiceTypeError("Please select Service Type");
      isValid = false;
    }
    if (serviceCategory == "") {
      setServiceCategoryError("Please select Service Category");
      isValid = false;
    }

    if (serviceHours == "") {
      setServiceHourError("Please select Hours");
      isValid = false;
    }

    if (serviceMinute == "") {
      setServiceMinuteError("Please select Minutes");
      isValid = false;
    }

    if (servicePrice == "") {
      setServicePriceError("Please enter your service price");
      isValid = false;
    }

    if (isValid == true) {
      addService();
    }
  }

  const defaultService = async (id:any) => {
    try {
      const fd = new FormData();
      fd.append("vendor_service_id", cookies.vendor_id);
      const response = await authService().defaultService(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        setServiceList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  /* const getServiceList = async () => {
    try {
      const response = await authService().getServiceList();
      if (response.data.success) {
        toast.success(response.data.message);
        setServiceList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  }; */

  const deleteService = async (id: any) => {
    try {
      const response = await authService().deleteService(id);
      if (response.data.success) {
        toast.success(response.data.message);
        // getServiceList();
        defaultService(cookies.vendor_id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const addService = async () => {
    const fd = new FormData();
    fd.append("service_name", serviceName);
    fd.append("category_id", serviceCategory);
    fd.append("service_type_id", serviceType);
    fd.append("service_time", serviceHours + serviceMinute);
    fd.append("charges", servicePrice);
    fd.append("vendor_detail_id", cookies.vendor_id);
    fd.append("is_extra_time", "1");
    try {
      const response = await authService().addService(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        handleClose();
        // getServiceList();
        defaultService(1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  return (
    <AuthLayout>
      <Modal className="serviceModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2>{t("serviceDetails")}</h2>
          <h3>{t("serviceDescPopup")} </h3>
          <Row className="mt-5">
            <Col className="col-md-12">
              <InputText
                placeholder={t("serviceName")}
                type="text"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
              ></InputText>
              <small className="form-text text-danger">{serviceError}</small>
            </Col>
            <Col className="col-md-12 ">
              <Form.Select
                aria-label="Default select example"
                className="mb-3"
                onChange={(e) => {
                  getServiceType(e.target.value);
                  setServiceCategory(e.target.value);
                }}
              >
                <option value="">{t("serviceCategory")}</option>
                {serviceCategoryList.map((cat: any, index: any) => (
                  <option value={cat.id} key={index}>
                    {cat.name}
                  </option>
                ))}
              </Form.Select>
              <small className="form-text text-danger">
                {serviceCategoryError}
              </small>
            </Col>
            <Col className="col-md-12 ">
              <Form.Select
                aria-label="Default select example"
                className="mb-3"
                onChange={(e) => {
                  setServiceType(e.target.value);
                }}
              >
                <option value="">{t("serviceType")}</option>
                {serviceTypeList.map((type: any, index: any) => (
                  <option value={type.id} key={index}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
              <small className="form-text text-danger">
                {serviceTypeError}
              </small>
            </Col>
          </Row>
          <Row className="mb-3 ">
            <Col className="col-md-6">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setServiceHours(e.target.value);
                }}
              >
                <option value="">{t("hours")}</option>
                <option value="01:">1:00</option>
                <option value="02:">2:00</option>
                <option value="03:">3:00</option>
                <option value="04:">4:00</option>
                <option value="05:">5:00</option>
              </Form.Select>
              <small className="form-text text-danger">
                {serviceHourError}
              </small>
            </Col>

            <Col className="col-md-6">
              <Form.Select
                className="minutes"
                aria-label="Default select example"
                onChange={(e) => {
                  setServiceMinute(e.target.value);
                }}
              >
                <option value="">{t("minutes")}</option>
                <option value="05">05:00</option>
                <option value="10">10:00</option>
                <option value="15">15:00</option>
                <option value="20">20:00</option>
                <option value="25">25:00</option>
                <option value="30">30:00</option>
                <option value="35">35:00</option>
                <option value="40">40:00</option>
                <option value="45">45:00</option>
                <option value="50">50:00</option>
                <option value="55">55:00</option>
              </Form.Select>
              <small className="form-text text-danger">
                {serviceMinuteError}
              </small>
            </Col>
          </Row>

          <Row>
            <Col className="col-md-6">
              <InputText
                placeholder={t("₹45.00")}
                type="number"
                value={servicePrice}
                onChange={(e) => setServicePrice(e.target.value)}
              ></InputText>
              <small className="form-text text-danger">
                {servicePriceError}
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-4 addBreak"></Col>
          </Row>
          <Row>
            <Col className="col-md-6 ">
              <CustomButton
                class="cancel"
                ButtonTitle={t("cancel")}
                onClickButton={() => handleClose()}
              ></CustomButton>
            </Col>
            <Col className="col-md-6 ">
              <CustomButton
                class="save"
                ButtonTitle={t("save")}
                onClickButton={() => addServiceInfo()}
              ></CustomButton>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal className="editService" show={editshow} onHide={editClose}>
        <Modal.Body>
          <center><h2>{selectedCat}</h2></center>
          <Row className="mb-3 ">
            <Col className="col-md-6">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setServiceHours(e.target.value);
                }}
              >
                <option value="">{t("hours")}</option>
                <option value="01:">1:00</option>
                <option value="02:">2:00</option>
                <option value="03:">3:00</option>
                <option value="04:">4:00</option>
                <option value="05:">5:00</option>
              </Form.Select>
              <small className="form-text text-danger">
                {serviceHourError}
              </small>
            </Col>

            <Col className="col-md-6">
              <Form.Select
                className="minutes"
                aria-label="Default select example"
                onChange={(e) => {
                  setServiceMinute(e.target.value);
                }}
              >
                <option value="">{t("minutes")}</option>
                <option value="05">05:00</option>
                <option value="10">10:00</option>
                <option value="15">15:00</option>
                <option value="20">20:00</option>
                <option value="25">25:00</option>
                <option value="30">30:00</option>
                <option value="35">35:00</option>
                <option value="40">40:00</option>
                <option value="45">45:00</option>
                <option value="50">50:00</option>
                <option value="55">55:00</option>
              </Form.Select>
              <small className="form-text text-danger">
                {serviceMinuteError}
              </small>
            </Col>
          </Row>

          <Row>
            <Col className="col-md-6">
              <InputText
                placeholder={t("₹45.00")}
                type="number"
                value={servicePrice}
                onChange={(e) => setServicePrice(e.target.value)}
              ></InputText>
              <small className="form-text text-danger">
                {servicePriceError}
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-4 addBreak"></Col>
          </Row>
          <Row>
            <Col className="col-md-6 ">
              <CustomButton
                class="cancel"
                ButtonTitle={t("cancel")}
                onClickButton={() => editClose()}
              ></CustomButton>
            </Col>
            <Col className="col-md-6 ">
              <CustomButton
                class="save"
                ButtonTitle={t("save")}
                onClickButton={() => addServiceInfo()}
              ></CustomButton>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Row className="service">
        <Col className="col-md-1">
          <Image
            onClick={backToAbout}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.serviceBar} />
        </Col>
        <center>
          <h2>{t("addService")}</h2>
          <h6>{t("serviceDesc")}</h6>
        </center>
      </Row>
      <Row className="formRow mb-4 service">
        {serviceList.map((service: any, index: any) => (
          <Row key={index}>
            <Col className="col-md-1">
              
              {service.is_default == '1'? 
           
            <Form.Check
          aria-label="option 1"
          label={t("")}
        /> 

              : 
              <Image
              className="deleteService"
              src={icons.deleteService}
              onClick={() => {
                deleteService(service.id);
              }}
            ></Image>   
              }
              
              </Col>
              <Col className="col-md-7">
              <h4 className="serviceName">{service.service_name}</h4>
              <h5 className="serviceDuration">{service.service_time}</h5>
            </Col>
            
            <Col className="col-md-4">
              <h4 className="serviceAmount">
                {service.currency.currency_icon} {service.charges}
                <span>
                  <Image src={icons.hoursArrow} onClick={() => editOpen(service.service_name)} />{" "}
                </span>
              </h4>
            </Col>
          </Row>
        ))}
        </Row>
      <Row className="add">  
        <h3 onClick={handleShow} className="addService">
          {t("+addService")}
        </h3>
        <center><CustomButton
          class="serviceButton"
          ButtonTitle={t("continue")}
          onClickButton={() => goToHelp()}
        ></CustomButton></center>
      </Row>
    </AuthLayout>
  );
};

export default Service;
