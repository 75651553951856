import React from "react";
import logo from "./logo.svg";
import { Toaster } from "react-hot-toast";

// Navigation to all oter pages
import Navigation from "./Navigators/Navigation";

function App() {
  return <>
    <Navigation />
    <Toaster
      position="bottom-right"
      toastOptions={{ duration: 4500 }}
      reverseOrder={false}
    />
  </>;
}

export default App;
