import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Businesshours.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import toast from "react-hot-toast";
import AuthService from "../../Services/auth.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Businesshours: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [isChecked1, setIsChecked1] = useState(true);

  const [day, setDay] = useState<any>([]);
  const [slotType, setSlotType] = useState<any>([]);
  const [slots, setSlots] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [businessTime, setBusinessTime] = useState({ Monday: "10:00 - 19:00" });
  const [startTime, setStartTime] = useState("10:00");
  const [endTime, setEndTime] = useState("19:00");

  const [startTimeMonday, setStartTimeMonday] = useState("10:00");
  const [endTimeMonday, setEndTimeMonday] = useState("19:00");
  const [isOpenMonday, setIsOpenMonday] = useState(false);
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const getScheduleDays = async () => {
    try {
      const response = await authService().getScheduleDays();
      if (response.data.success) {
        toast.success(response.data.message);
        const days = response.data.data;
        setDay(days);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };
  useEffect(() => {
    getScheduleDays();
    commonDropdowns();
  }, []);

  const handleSubmit = () => {
    // Validate start and end times
    if (startTime < endTime) {
      // Update state variables
      setStartTime(startTime);
      setEndTime(endTime);
      handleClose();
    } else {
      // Handle validation error
      console.error("Start time must be earlier than end time.");
    }
  };

  // function for go back to about page
  function backToAbout() {
    navigate(-1);
  }

  const [show, setShow] = useState(false);
  const [selectedDay, setSelectedDay] = useState<any>();

  const handleClose = () => {
    setDay((prevDays: any) =>
      prevDays.map((day: any) =>
        day.id === selectedDay.id ? selectedDay : day
      )
    );
    setShow(false);
  };
  const handleShow = (day: any) => {
    setSelectedDay(day); // Set the selected day
    setShow(true);
  };

  function postData(data: any) {
    return data
      .filter(({ onOff }: any) => onOff !== false && onOff !== undefined) // Exclude objects where onOff is false
      .map(({ day_of_week, start_time, end_time, slots }: any) => ({
        day_of_week,
        start_time,
        end_time,
        slots,
      }));
  }

  const commonDropdowns = async () => {
    try {
      const response = await authService().commonDropdowns();
      if (response.data.success) {
        toast.success(response.data.message);
        setSlotType(response.data?.slot_types);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  // function for checkbox validation and move to next screen after successfull validation
  const addHours = async () => {
    console.log(location.state)
    const fd = new FormData();
    fd.append("team_size_info", location.state?.teamData?.size || "");
    fd.append("vendor_detail_id", cookies.vendor_id || "");
    fd.append("week_schedule", JSON.stringify(postData(day)) || "");
    try {
      const response = await authService().storeSchedule(fd);
      toast.success(response.data.message);
      if (response.data.success) {
        if (response.data?.data?.profile_flow_screen) {
          setCookie(CommonCookie.profile, response.data?.data?.profile_flow_screen, {
            path: "/",
            secure: true,
            sameSite: "lax",
          });
        }
        navigate("/service");
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error?.data?.message || "Oops, Something went wrong.");
    }
  }

  return (
    <AuthLayout>
      <Modal className="businessModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2>{selectedDay?.full_name}</h2>
          <h3>{t("setBusinessHours")}</h3>
          <Row className="mb-4 mt-5">
            <Col className="col-md-4">
              <span>{t("openingHours")}</span>
            </Col>
            <Col className="col-md-4">
              <Form.Control
                as="select"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  selectedDay.start_time = e.target.value;
                }}
              >
                {/* Add options for times */}
                <option value="1:00">1:00</option>
                <option value="2:00">2:00</option>
                <option value="3:00">3:00</option>
                <option value="4:00">4:00</option>
                <option value="5:00">5:00</option>
                <option value="6:00">6:00</option>
                <option value="7:00">7:00</option>
                <option value="8:00">8:00</option>
                <option value="9:00">9:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                <option value="24:00">24:00</option>
                {/* Add more options */}
              </Form.Control>
            </Col>
            <Col className="col-md-4">
              <Form.Control
                as="select"
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value);
                  selectedDay.end_time = e.target.value;
                }}
              >
                {/* Add options for times */}
                <option value="1:00">1:00</option>
                <option value="2:00">2:00</option>
                <option value="3:00">3:00</option>
                <option value="4:00">4:00</option>
                <option value="5:00">5:00</option>
                <option value="6:00">6:00</option>
                <option value="7:00">7:00</option>
                <option value="8:00">8:00</option>
                <option value="9:00">9:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                <option value="24:00">24:00</option>
                {/* Add more options */}
              </Form.Control>
            </Col>
          </Row>
          {selectedDay?.slots.map((slot: any, index: any) => (
            <Row key={index}>
              <Col className="col-md-4">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => (slot.slot_type = e.target.value)}
                >
                  <option value="">{t("Select Slot")}</option>
                  {slotType.map((cat: any, ind: any) => (
                    <option value={cat.id} key={ind}>
                      {cat.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-md-4">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => (slot.start_time = e.target.value)}
                >
                  <option value="1:00">1:00</option>
                  <option value="2:00">2:00</option>
                  <option value="3:00">3:00</option>
                  <option value="4:00">4:00</option>
                  <option value="5:00">5:00</option>
                  <option value="6:00">6:00</option>
                  <option value="7:00">7:00</option>
                  <option value="8:00">8:00</option>
                  <option value="9:00">9:00</option>
                  <option value="10:00">10:00</option>
                  <option value="11:00">11:00</option>
                  <option value="12:00">12:00</option>
                  <option value="13:00">13:00</option>
                  <option value="14:00">14:00</option>
                  <option value="15:00">15:00</option>
                  <option value="16:00">16:00</option>
                  <option value="17:00">17:00</option>
                  <option value="18:00">18:00</option>
                  <option value="19:00">19:00</option>
                  <option value="20:00">20:00</option>
                  <option value="21:00">21:00</option>
                  <option value="22:00">22:00</option>
                  <option value="23:00">23:00</option>
                  <option value="24:00">24:00</option>
                </Form.Select>
              </Col>
              <Col className="col-md-4">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => (slot.end_time = e.target.value)}
                >
                  <option value="1:00">1:00</option>
                  <option value="2:00">2:00</option>
                  <option value="3:00">3:00</option>
                  <option value="4:00">4:00</option>
                  <option value="5:00">5:00</option>
                  <option value="6:00">6:00</option>
                  <option value="7:00">7:00</option>
                  <option value="8:00">8:00</option>
                  <option value="9:00">9:00</option>
                  <option value="10:00">10:00</option>
                  <option value="11:00">11:00</option>
                  <option value="12:00">12:00</option>
                  <option value="13:00">13:00</option>
                  <option value="14:00">14:00</option>
                  <option value="15:00">15:00</option>
                  <option value="16:00">16:00</option>
                  <option value="17:00">17:00</option>
                  <option value="18:00">18:00</option>
                  <option value="19:00">19:00</option>
                  <option value="20:00">20:00</option>
                  <option value="21:00">21:00</option>
                  <option value="22:00">22:00</option>
                  <option value="23:00">23:00</option>
                  <option value="24:00">24:00</option>
                </Form.Select>
              </Col>
            </Row>
          ))}
          <Row>
            <Col className="col-md-12 addBreak">
              <h3
                onClick={() => {
                  if (selectedDay) {
                    const updatedSlots = [
                      ...selectedDay.slots,
                      {
                        start_time: "",
                        end_time: "",
                        slot_type: "",
                      },
                    ];
                    setSelectedDay({
                      ...selectedDay,
                      slots: updatedSlots,
                    });
                  }
                }}
              >
                {t("addSlot")}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6 ">
              <CustomButton
                class="cancel"
                ButtonTitle={t("cancel")}
                onClickButton={() => handleClose()}
              ></CustomButton>
            </Col>
            <Col className="col-md-6 ">
              <CustomButton
                class="save"
                ButtonTitle={t("save")}
                onClickButton={() => handleSubmit()}
              ></CustomButton>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row className="businessHours">
        <Col className="col-md-1">
          <Image
            onClick={backToAbout}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.workBar} />
        </Col>
        <center>
          <h2>{t("businessHours")}</h2>
          <h6>{t("clientFind")}</h6>
        </center>
      </Row>

      {typeof Array.isArray(day) ? (
        day.map((cat: any, ind: any) => (
          <Row className="formRow businessHours" key={ind}>
            <Col className="col-md-6 ">
              <Form.Check
                type="switch"
                name="businessDays"
                aria-label="option 1"
                label={cat.full_name}
                value={cat.onOff}
                checked={cat.onOff}
                onChange={(e) => (cat.onOff = e.target.checked)}
              />
            </Col>
            <Col className="col-md-6 ">
              <h4 className="businessTime">
                {cat.start_time} - {cat.end_time}{" "}
                <span>
                  {" "}
                  <Image
                    src={icons.hoursArrow}
                    onClick={() => handleShow(cat)}
                  />{" "}
                </span>
              </h4>
            </Col>
          </Row>
        ))
      ) : (
        // Handle unexpected data format here
        <p>Unexpected data format</p>
      )}
      <center>
      <CustomButton
        class="businessHourButton"
        ButtonTitle={t("continue")}
        onClickButton={() => addHours()}
      ></CustomButton></center>
    </AuthLayout>
  );
};

export default Businesshours;
