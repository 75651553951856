import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Subscription.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import { useDropzone } from 'react-dropzone';
import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import CustomButton from "../../Components/CustomButton/CustomButton";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import InputText from "../../Components/CustomInputText/InputText";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
const Subscription: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  function booked(){
  }
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>
        <Row className="dashboardRow subscription">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h2>{t("glam9Subscription")}</h2>
              <p>You can use Glam9 free for up to 14 Days,
              with full access to everything offered on the plan you sign up for.</p>
            </div>
          </Row> 
          <div className="rowPackage">
              <Col className="col-md-6">
                 <Row>
                  <Col className="col-md-8">
                    <h5>{t("light")}</h5>
                    <p>{t("subDesc")}</p>
                  </Col>
                  <Col className="col-md-4">
                  <Form.Check type='radio' name="glamStatus" checked={true} aria-label="option 1" value={t("light")} label={t("₹999/mo.")} />
                  </Col>
                 </Row> 
              </Col>
              <Col className="col-md-6">
                 <Row>
                  <Col className="col-md-8">
                    <h5>{t("pro")}</h5>
                    <p>{t("subDesc")}</p>
                  </Col>
                  <Col className="col-md-4">
                  <Form.Check type='radio' name="glamStatus" aria-label="option 1" value={t("46staffMembers")} label={t("₹1999/mo.")} />
                  </Col>
                 </Row> 
              </Col>
              <CustomButton class="subscribe" ButtonTitle={t("continue")} onClickButton={() => booked()} ></CustomButton>
            </div>
        </Row>  
        </Col>
      </Row>  
  );
};
export default Subscription;