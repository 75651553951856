import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Newpackage.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDropzone } from 'react-dropzone';


import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";


const Newpackage: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState('home');
  const [selectedImage, setSelectedImage] = useState(null);

  const [name, setName] = useState("");

  const [packageName, setPackageName] = useState("");
  const [packageNameError, setPackageNameError] = useState("");

  const [menu, setMenu] = useState("");
  const [menuError, setMenuError] = useState("");

  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState("");

  const [schedule, setSchedule] = useState("");
  const [scheduleError, setScheduleError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  /* business hours */


  
  



  

  

  function addHours(){
    navigate('/service');
  // }
}

/* business hours */
  
  
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };

  function gotoCatalog(){
    navigate('/catalog');
  }
  
  function gotoAbout(){
    setPackageNameError("");
    setMenuError("");
    setDescError("");
    setScheduleError("");
    setPriceError("");
    let isValid = true;
    if(packageName == ''){
      setPackageNameError("please enter Package Name");
      isValid = false;
    }
    if(menu == ''){
      setMenuError("please select Menu Category");
      isValid = false;
    }
    if(schedule == ''){
      setScheduleError("please select Any Schedule");
      isValid = false;
    }
    if(price == ''){
      setPriceError("please enter Price");
      isValid = false;
    }
  }
  function booked(){

  }
    
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>
        <Row className="dashboardRow package">
          <Row className="col-md-12">
              <h2>{t("newPackage")}</h2>
          </Row>  
          <hr></hr>        
          <div className="col-md-7">
          <div className="row">
            <div className="col-md-12">
            <h3>{t("basicDetails")}</h3>
            </div>
          </div>  
          <div className="row">
            <div className="col-md-6">
              <InputText placeholder={t("packageName")} type="text" value="" onChange={(e) => setPackageName(e.target.value)} ></InputText>  
              <small className="form-text text-danger">{packageNameError}</small>
            </div>
            <div className="col-md-6">
              <Form.Select className="last" aria-label="Default select example" onChange={(e) => setPackageName(e.target.value)}>
                <option>{t("menuCategory")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
              <small className="form-text text-danger">{menuError}</small>
            </div>
          </div>
          <div className="row">
              <Form.Control as="textarea" placeholder={t("description")} rows={3} onChange={(e) => setMenu(e.target.value)} />
              <small className="form-text text-danger">{descError}</small>
          </div>

          <div className="row services">
            <h3>{t("services")}</h3>
            <Col className="col-md-6">
                 <Row>
                  <Col className="col-md-6">
                    <h5>{t("padicure")}</h5>
                    <p>Process time: 1Hr</p>
                  </Col>
                  <Col className="col-md-6">
                    <Form.Check type='radio' name="glamStatus" checked={true} aria-label="option 1" value={t("light")} label={t("₹120")} />
                  </Col>
                 </Row> 
              </Col>
              <Col className="col-md-6">
                 <Row>
                  <Col className="col-md-6">
                  <h5>{t("padicure")}</h5>
                  <p>Process time: 1Hr</p>
                  </Col>
                  <Col className="col-md-6">
                  <Form.Check type='radio' name="glamStatus" aria-label="option 1" value={t("46staffMembers")} label={t("₹120")} />
                  </Col>
                 </Row> 
              </Col>
            </div>
            <div className="col-md-12 third">
            <Form.Select aria-label="Default select example" onChange={(e) => setSchedule(e.target.value)}>
                <option>{t("scheduleType")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
              <small className="form-text text-danger">{scheduleError}</small>
            </div>
          
          
          <div className="row">
            <h3>{t("pricing")}</h3>
            <div className="col-md-6 first">
              <Form.Select className="priceType" aria-label="Default select example" onChange={(e) => setPrice(e.target.value)}>
                <option>{t("priceType")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
              <small className="form-text text-danger">{priceError}</small>
            </div>
            
            <div className="col-md-6 second">
              <InputText placeholder={t("retailPrice")} type="text" value="" onChange={(e) => setPackageName(e.target.value)} ></InputText>  
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-3">
              <h3>{t("onlineBooking")}</h3>
            </div>
            <div className="col-md-3 booking">
              <Form.Check type="switch" aria-label="option 1" label={t("")} />
            </div>
          </div>
                <div className="row">
                  <CustomButton class="cancel" ButtonTitle={t("cancel")} onClickButton={() => gotoCatalog()} ></CustomButton>
                  <CustomButton class="save" ButtonTitle={t("save")} onClickButton={() => gotoAbout()} ></CustomButton>
                </div>
              </div>
            </Row>  
        </Col>
      </Row>  
  );
};

export default Newpackage;