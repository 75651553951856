import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from "react-bootstrap/InputGroup";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Team.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import { useDropzone } from "react-dropzone";

import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";
import VendorService from "../../Services/vendor.service";
import toast from "react-hot-toast";

const Team: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  /* business hours */
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [checked, setChecked] = React.useState(false);
  /* business hours */

  const [show, setShow] = useState(false);
  const [timeShow, setTimeShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const vendorService = VendorService();
  const [teamMemberList, setTeamMemberList] = useState([]);

  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  useEffect(() => {
    getTeamList();
    console.log("first")
  }, []);

  const getTeamList = async () => {
    const fd = new FormData();
    fd.append("vendor_detail_id", cookies.vendor_id);
    try {
      const response = await vendorService().getTeamList(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        setTeamMemberList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };
  const timeHandleClose = () => setTimeShow(false);
  const timeHandleShow = () => setTimeShow(true);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSwitchChange = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  function gotoSchedule() {
    navigate("/schedule");
  }

  function addServiceInfo() {}

  function gotoAddTeam() {
    navigate("/addTeam");
  }
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
        <AdminMenu />
      </Col>
      <Col className="col-md-10">
        <AdminHeader />

        <Modal className="filterModal" show={show}>
          <Modal.Body>
            <Row className="">
              <Col className="col-md-10">
                <h2>{t("filter")}</h2>
              </Col>
              <Col className="col-md-2">
                <Image
                  src={icons.close}
                  onClick={handleClose}
                  className="cancel"
                ></Image>
              </Col>
            </Row>
            <hr></hr>
            <center>
              <Row>
                <Form.Select
                  onChange={(e) => setStatus(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="">{t("status")}</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </Row>

              <Row>
                <Col className="col-md-6 ">
                  <CustomButton
                    class="cancel"
                    ButtonTitle={t("cancel")}
                    onClickButton={() => handleClose()}
                  ></CustomButton>
                </Col>
                <Col className="col-md-6 ">
                  <CustomButton
                    class="save"
                    ButtonTitle={t("save")}
                    onClickButton={() => addServiceInfo()}
                  ></CustomButton>
                </Col>
              </Row>
            </center>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal className="timeOffModal" show={timeShow}>
          <Modal.Body>
            <Row className="">
              <Col className="col-md-10">
                <h2>{t("addTimeOff")}</h2>
              </Col>
              <Col className="col-md-2">
                <Image
                  src={icons.close}
                  onClick={timeHandleClose}
                  className="cancel"
                ></Image>
              </Col>
            </Row>
            <hr></hr>
            <center>
              <Row className="timeRow">
                <Col className="col-md-6">
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">{t("teamMember")}</option>
                    <option value="Jack Lion">Jack Lion</option>
                    <option value="Lara Ray">Lara Ray</option>
                    <option value="Loren Jem">Loren Jem</option>
                    <option value="Franklin Jobs">Franklin Jobs</option>
                  </Form.Select>
                </Col>
                <Col className="col-md-6">
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">{t("type")}</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="timeRow">
                <Col className="col-md-6">
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">{t("startDate")}</option>
                    <option value="Jack Lion">Jack Lion</option>
                    <option value="Lara Ray">Lara Ray</option>
                    <option value="Loren Jem">Loren Jem</option>
                    <option value="Franklin Jobs">Franklin Jobs</option>
                  </Form.Select>
                </Col>
                <Col className="col-md-3">
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">{t("startTime")}</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Col>
                <Col className="col-md-3">
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">{t("endTime")}</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="timeRow">
                <div className="col-md-3">
                  <h3>{t("repeat")}</h3>
                </div>

                <div className="col-md-3">
                  <Form.Check
                    type="switch"
                    onChange={handleSwitchChange}
                    aria-label="option 1"
                    label={t("")}
                  />
                </div>
              </Row>
              {isDropdownVisible && (
                <Row className="timeRow">
                  <Col className="col-md-6">
                    <Form.Select
                      onChange={(e) => setStatus(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">{t("reportUntil")}</option>
                      <option value="10 Mins">10 Mins</option>
                      <option value="20 Mins">20 Mins</option>
                      <option value="30 Mins">20 Mins</option>
                      <option value="40 Mins">40 Mins</option>
                    </Form.Select>
                  </Col>
                  <Col className="col-md-6"></Col>
                </Row>
              )}
              <Row>
                <textarea
                  placeholder={t("description")}
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </Row>
              <Row>
                <Col className="col-md-6 ">
                  <Form.Check
                    type="checkbox"
                    aria-label="option 1"
                    label={t("approved")}
                  />
                </Col>
                <Col className="col-md-6 ">
                  <label>Time off total: 7h</label>
                </Col>
              </Row>
              <Row>
                <p>{t("addtimeDesc")}</p>
              </Row>
              <hr></hr>
              <Row>
                <Col className="col-md-6 ">
                  <CustomButton
                    class="cancel"
                    ButtonTitle={t("cancel")}
                    onClickButton={() => timeHandleClose()}
                  ></CustomButton>
                </Col>
                <Col className="col-md-6 ">
                  <CustomButton
                    class="save"
                    ButtonTitle={t("save")}
                    onClickButton={() => addServiceInfo()}
                  ></CustomButton>
                </Col>
              </Row>
            </center>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Row className="dashboardRow team">
          <Row className="col-md-12">
            <div className="col-md-6">
              <h3>{t("team")}</h3>
              <InputText
                placeholder={t("search")}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></InputText>
            </div>
            <div className="col-md-6">
              <CustomButton
                class="teamButton"
                ButtonTitle={t("addMember")}
                onClickButton={() => gotoAddTeam()}
              ></CustomButton>
              <Button onClick={handleShow} className="filterButton">
                Filters <Image src={icons.filter}></Image>
              </Button>
            </div>
          </Row>
          <Row className="teamTable">
            <Table>
              <thead>
                <tr>
                  <th>
                    <Form.Check label={t("name")} />
                  </th>
                  <th>{t("email")}</th>
                  <th>{t("phoneNumber")}</th>
                  <th>{t("ratings")}</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {teamMemberList.map((team: any, ind: any) => (
                  <tr key={ind}>
                    <td>
                      <Form.Check label={t("")} />
                      <Image src={team.profile_image}></Image>
                      <h2>
                        {team.first_name} {team.last_name}
                      </h2>
                    </td>
                    <td>
                      <label>{team.email}</label>
                    </td>
                    <td>
                      <label>
                        +{team.country_code} {team.mobile}
                      </label>
                    </td>
                    <td>
                      <div className="rating">
                        <Image src={icons.rating}></Image>
                        <Image src={icons.rating}></Image>
                        <Image src={icons.rating}></Image>
                        <Image src={icons.rating}></Image>
                        <Image src={icons.rating}></Image>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <Image src={icons.edit}></Image>
                        <Image src={icons.teamCalender}></Image>
                        <Image
                          onClick={gotoSchedule}
                          src={icons.calenderEdit}
                        ></Image>
                        <Image
                          onClick={timeHandleShow}
                          src={icons.timer}
                        ></Image>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};
export default Team;
