import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Newservice.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDropzone } from 'react-dropzone';


import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";

const Newservice: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState('home');
  const [selectedImage, setSelectedImage] = useState(null);

  

  /* business hours */


  
  const [webError, setWebError] = useState("");



  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [type, setType] = useState("");
  const [typeError, setTypeError] = useState("");

  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState("");

  const [duration, setDuration] = useState("");
  const [durationError, setDurationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");

  const [priceType, setPriceType] = useState("");
  const [priceTypeError, setPriceTypeError] = useState("");

  

  function addHours(){
    navigate('/service');
  // }
}

/* business hours */
  
  
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };

  function gotoCatalog(){
    navigate('/catalog');
  }
  
  function gotoAbout(){
    var isValid = true;
    setNameError("");
    setTypeError("");
    setDescError("");
    setDurationError("");
    setPriceError("");
    setPriceTypeError("");
    if(name == ''){
      setNameError("Please enter Service Name");
      isValid = false;
    }
    if(type == ''){
      setTypeError("Please enter Service Name");
      isValid = false;
    }
    if(desc == ''){
      setDescError("Please enter Service Name");
      isValid = false;
    }
    if(duration == ''){
      setDurationError("Please enter Service Name");
      isValid = false;
    }
    if(price == ''){
      setPriceError("Please enter Service Name");
      isValid = false;
    }
    if(priceType == ''){
      setPriceTypeError("Please enter Service Name");
      isValid = false;
    }
  }
  function booked(){

  }
    
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      < AdminMenu/>
      </Col>
      <Col className="col-md-10">
      < AdminHeader/>
        <Row className="dashboardRow service">
          <Row className="col-md-12">
              <h2>{t("newService")}</h2>
          </Row>  
          <hr></hr>        
          <div className="col-md-7">
          <div className="row">
            <div className="col-md-12">
            <h3>{t("basicDetails")}</h3>
              <InputText placeholder={t("serviceName")} type="text" value="" onChange={(e) => setName(e.target.value)} ></InputText>  
            </div>
          </div>  
          <div className="row">
            <div className="col-md-6">
              <InputText placeholder={t("serviceType")} type="text" value="" onChange={(e) => setName(e.target.value)} ></InputText>  
            </div>
            <div className="col-md-6">
              <Form.Select aria-label="Default select example">
                <option>{t("menuCategory")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
            </div>
          </div>
          <div className="row">
              <Form.Control as="textarea" placeholder={t("description")} rows={3} />
          </div>
          
          <div className="row">
            <h3>{t("priceDuration")}</h3>
            <div className="col-md-4 first">
              <Form.Select aria-label="Default select example">
                <option>{t("duration")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
            </div>
            <div className="col-md-4 second">
              <Form.Select aria-label="Default select example">
                <option>{t("priceType")}</option>
                <option value="manicure">{t("medicure")}</option>
                <option value="pedicure">{t("padicure")}</option>
                <option value="hairService">{t("hairService")}</option>
                <option value="hairSpa">{t("hairSpa")}</option>
              </Form.Select>
            </div>
            <div className="col-md-4 third">
              <InputText placeholder={t("price")} type="text" value="" onChange={(e) => setName(e.target.value)} ></InputText>  
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-3">
              <h3>{t("extraTime")}&nbsp;<Image src={icons.serviceHelp}></Image></h3>
            </div>
            <div className="col-md-3">
              <Form.Check type="switch" aria-label="option 1" label={t("")} />
            </div>
          </div>
                <div className="row">
                  <CustomButton class="cancel" ButtonTitle={t("cancel")} onClickButton={() => gotoCatalog()} ></CustomButton>
                  <CustomButton class="save" ButtonTitle={t("save")} onClickButton={() => gotoAbout()} ></CustomButton>
                </div>
              </div>
            </Row>  
        </Col>
      </Row>  
  );
};

export default Newservice;