import React, { FC } from "react";
import { useState, useEffect } from "react";
// Importing bootstrap and css
import { Button, Col, Form, Image, Nav, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { icons } from "../../Assets/icons";
import { useNavigate, Link } from "react-router-dom";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import "./AdminHeader.css";
import InputText from "../../Components/CustomInputText/InputText";
import { useCookies } from "react-cookie";
import CommonCookie from "../CommonCookie";
import toast from "react-hot-toast";

const AdminHeader: React.FC = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  function logOut() {
    toast.success("User Logout successfully.");
    removeCookie(CommonCookie.user, { path: '/' });
    removeCookie(CommonCookie.token, { path: '/' });
    removeCookie(CommonCookie.userType, { path: '/' });
    removeCookie(CommonCookie.profile, { path: '/' });
    removeCookie(CommonCookie.vendor_id, { path: '/' });
    navigate("/");
  }

  const t = useAppTranslation();
  return (
    <Row className="header">
      <Col className="col-md-4">
        <InputText
          placeholder={t("search")}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></InputText>
      </Col>
      <Col className="col-md-2"></Col>
      <Col className="col-md-6 rightHeader">
        <div className="userProfile">
          <Form.Label>John Doe</Form.Label>
          <Image src={icons.userImage}></Image>
        </div>
        <div className="images">
        <Image className="notification" src={icons.notification}></Image>
        <Image src={icons.help}></Image>
        <Image src={icons.logout} onClick={()=>logOut()}></Image>
        </div>
      </Col>
    </Row>
  );
};

export default AdminHeader;
