import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Loginpassword.css";
import toast from "react-hot-toast";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";
import useAuth from "../../core/hooks/useAuth";

const Loginpassword: React.FC = () => {
  const t = useAppTranslation();
  const authService = AuthService();
  const navigate = useNavigate();
  const location = useLocation();
  const [passworderror, setPassworderror] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);
  const { setAuth } = useAuth();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  // function for go back to login page
  function backLogin() {
    navigate(-1);
  }

  useEffect(() => {
    if (location.state === null) {
      navigate("/");
    }
    setLoginData(location.state?.loginData);
    switch (Number(cookies.profile) + 1) {
      case 1:
        return navigate("/business");
      case 2:
        return navigate("/business");
      case 3:
        return navigate("/work");
      case 4:
        return navigate("/work");
      case 5:
        return navigate("/teamsize");
      case 6:
        return navigate("/teamsize");
      case 7:
        return navigate("/service");
      case 8:
        return navigate("/help");
      case 9:
        return navigate("/help");
      case 10:
        return navigate("/dashboard");
      default:
        return navigate("/loginpassword");
    }
  }, [])

  // function for password validation and redirect to business page after successsfully validation
  function checkPassword() {
    login();
  }
  // function for view password
  function showpassword() {
    setType(type === "password" ? "text" : "password");
  }

  function forgotPassword() {
    navigate("/forgot");
  }

  const login = async () => {
    const fd = new FormData();
    fd.append("email", loginData.email || "");
    fd.append("password", password);
    try {
      const response = await authService().login(fd);
      toast.success(response.data.message);
      setLoginData((prevState: any) => ({
        ...prevState,
        password: password,
      }));
      if (response.data.success) {
        setCookie(CommonCookie.token, response.data?.data?.token, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        setCookie(CommonCookie.profile, response.data?.data?.profile_flow_screen, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        setCookie(CommonCookie.vendor_id, response.data?.data?.vendor_detail_id, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        switch (Number(response.data?.data?.profile_flow_screen) + 1) {
          case 1:
            return navigate("/business");
          case 2:
            return navigate("/business");
          case 3:
            return navigate("/work");
          case 4:
            return navigate("/work");
          case 5:
            return navigate("/teamsize");
          case 6:
            return navigate("/teamsize");
          case 7:
            return navigate("/service");
          case 8:
            return navigate("/help");
          case 9:
            return navigate("/help");
          case 10:
            return navigate("/dashboard");
          default:
            return navigate("/dashboard");
        }
      }
      setMessage(response.data.success);
    } catch (error: any) {
      setErrorStatus(true);
      setMessage(error);
      console.log(error);
      toast.error(error?.data?.message || "Oops, Something went wrong.");
    }
  };

  return (
    <AuthLayout>
      <Row className="loginpwdSection">
        <Col className="col-md-1">
          <Image onClick={backLogin} className="backButton" src={icons.back} />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.pwdBar} />
        </Col>
        <h2>{t("password")}</h2>
        <h6>{t("enterPassword1")}</h6>
      </Row>
      <Row className="loginpwdSection">
        <InputGroup className="mb-3">
          <Form.Control
            type={type}
            placeholder={t("password")}
            aria-label={t("password")}
            aria-describedby="basic-addon2"
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputGroup.Text id="basic-addon2" onClick={showpassword}>
            {t("show")}
          </InputGroup.Text>
        </InputGroup>
        <small id="emailHelp" className="form-text text-danger">
          {passworderror}
        </small>
        <span onClick={forgotPassword}>{t("forgotPassword")}</span>
        <CustomButton
          class="Button"
          ButtonTitle={t("continue")}
          onClickButton={() => checkPassword()}
        ></CustomButton>
        <p>
          By signing up I agree to the <a href="#">Terms & Conditions</a> and to
          the <a href="#">Privacy Policy</a>
        </p>
      </Row>
    </AuthLayout>
  );
};

export default Loginpassword;
