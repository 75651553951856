import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Previoususe.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import toast from "react-hot-toast";
import AuthService from "../../Services/auth.service";
import CommonCookie from "../../Components/CommonCookie";
import { useCookies } from "react-cookie";

const Previoususe: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [previous, setPrevious] = useState("");
  const [previousError, setpreviousError] = useState("");
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const prevToolsInfo = async () => {
    try {
      const response = await authService().prevToolsInfo();
      if (response.data.success) {
        toast.success(response.data.message);
        setTool(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const addToolsHelpInfo = async () => {
    const checkedIds = tool.filter((item: any) => item.isChecked).map((item: any) => item.id); 
    const fd = new FormData();
    fd.append("previous_tools_ids", checkedIds.join(",") || '');
    fd.append("help_info_ids", location.state?.data?.helpIds.join(",") || '');
    fd.append("previously_used_tools", previous === "Yes" ? "1" : "2");
    fd.append("vendor_detail_id", cookies.vendor_id);
    try {
      const response = await authService().addToolsHelpInfo(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        if (response.data?.data?.profile_flow_screen) {
          setCookie(CommonCookie.profile, response.data?.data?.profile_flow_screen, {
            path: "/",
            secure: true,
            sameSite: "lax",
          });
        }
        navigate("/set");
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  useEffect(() => {
    prevToolsInfo();
  }, []);

  const [tool, setTool] = useState([]);

  const [toolValue, setToolValue] = useState("");
  const [toolError, setToolError] = useState("");

  // function for go back to about page
  function backToAbout() {
    navigate(-1);
  }

  // function for checkbox validation and move to next screen after successfull validation
  function addWorkInfo() {
    let isValid = true;
    setpreviousError("");

    if (previous == "") {
      setpreviousError("Please Select Any One Option");
      isValid = false;
    }
    if (isValid == true) {
      navigate("/set");
      addToolsHelpInfo();
    }
  }

  function addServiceInfo() {
    setToolError("");
    let isValid = true;
    if (toolValue == "") {
      setToolError("Please check above tools");
      isValid = false;
    }
    if (isValid == true) {
      setShow(false);
      addToolsHelpInfo();
    }
  }

  return (
    <AuthLayout>
      <Modal className="toolsModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <h2>{t("checkTool")}</h2>
          <h3>{t("checkToolDesc")}</h3>
          <Row>
            <Col className="tools">
              {tool.map((cat: any, ind: any) => (
                <Form.Check
                  key={ind}
                  value={cat.id}
                  name="tools"
                  onChange={(e) => {
                    setToolValue(e.target.value);
                    cat.isChecked = true;
                  }}
                  aria-label="option 1"
                  label={cat.platform_name}
                />
              ))}
              <small className="form-text text-danger mt-4">{toolError}</small>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6 ">
              <CustomButton
                class="cancel"
                ButtonTitle={t("cancel")}
                onClickButton={() => handleClose()}
              ></CustomButton>
            </Col>
            <Col className="col-md-6 ">
              <CustomButton
                class="save"
                ButtonTitle={t("save")}
                onClickButton={() => addServiceInfo()}
              ></CustomButton>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Row className="previous">
        <Col className="col-md-1">
          <Image
            onClick={backToAbout}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.prevousBar} />
        </Col>
        <center>
          <h2>{t("previousUse")}</h2>
        </center>
      </Row>
      <Row className="previous formRow">
        <Col className="col-md-12">
          <Form.Check
            type="radio"
            className="teamSize"
            name="previous"
            aria-label="option 1"
            value="Yes"
            onChange={(e) => setPrevious(e.target.value)}
            label={t("yesIhave")}
          />
        </Col>
      </Row>
      <Row className="previous formRow">
        <Col className="col-md-12">
          <Form.Check
            type="radio"
            className="teamSize"
            name="previous"
            aria-label="option 1"
            value="No"
            onChange={(e) => setPrevious(e.target.value)}
            label={t("noIhavent")}
          />
          <small className="form-text text-danger">{previousError}</small>
        </Col>
        <CustomButton
          class="previousButton"
          ButtonTitle={t("continue")}
          onClickButton={() => addWorkInfo()}
        ></CustomButton>
      </Row>
    </AuthLayout>
  );
};

export default Previoususe;
