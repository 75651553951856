import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Confirm.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Confirm: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();

  const [streetAddress1, setstreetAddress1] = useState("");
  const [streetAddress1Error, setstreetAddress1Error] = useState("");
  const [streetAddress2, setstreetAddress2] = useState("");
  const [streetAddress2Error, setstreetAddress2Error] = useState("");
  const [city, setCity] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState<any>([]);
  const [stateError, setStateError] = useState("");
  const [country, setCountry] = useState<any>([]);
  const [countryError, setCountryError] = useState("");
  const [postcode, setPostcode] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [countryID, setCountryID] = useState("");
  const [stateID, setStateID] = useState("");
  const [className, setClassName] = useState("mb-3");
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  useEffect(() => {
    if (location.state === null) {
      navigate("/work");
    }
    getCountry();
  }, []);

  const getCountry = async () => {
    try {
      const response = await authService().getLocation();
      if (response.data.success) {
        // toast.success(response.data.message);
        setCountry(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const getState = async (countryId: any) => {
    setCountryID(countryId);
    try {
      const response = await authService().getLocation(countryId);
      if (response.data.success) {
        // toast.success(response.data.message);
        setState(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  const getCity = async (stateId: any) => {
    setStateID(stateId);
    try {
      const response = await authService().getLocation(countryID, stateId);
      if (response.data.success) {
        // toast.success(response.data.message);
        setCity(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  // function for go back to business screen
  function backToBusiness() {
    navigate(-1);
  }

  function checkPassword() {}

  // function about us form validation and redirection on next screen after successfull validation

  function addAboutInfo() {
    setClassName(className === "mb-3" ? "" : "mb-3");
    let isValid = true;
    setstreetAddress1Error("");
    setstreetAddress2Error("");
    setCityError("");
    setPostcodeError("");
    setStateError("");
    setCountryError("");

    if (streetAddress1 == "") {
      setstreetAddress1Error("Please enter your Street Address 1");
      isValid = false;
    }

    if (streetAddress2 == "") {
      setstreetAddress2Error("Please enter your Street Address 2");
      isValid = false;
    }
    if (postcode == "") {
      setPostcodeError("Please select your Post Code");
      isValid = false;
    }

    if (isValid == true) {
      storeBusinessAddress();
    }
  }

  function resetForm() {
    setstreetAddress1("");
    setstreetAddress2("");
    setPostcode("");
  }

  const storeBusinessAddress = async () => {
    const fd = new FormData();
    fd.append("address_line_1", streetAddress1 || "");
    fd.append("address_line_2", streetAddress2 || "");
    fd.append("country_id", countryID || "");
    fd.append("state_id", stateID || "");
    fd.append("city_id", selectedCity || "");
    fd.append("postal_code", postcode || "");
    fd.append("vendor_detail_id", cookies.vendor_id || "");
    fd.append("work_place", location.state?.location?.status || "");
    try {
      const response = await authService().storeBusinessAddress(fd);
      if (response.data.success) {
        toast.success(response.data.message);
        if (response.data?.data?.profile_flow_screen) {
          setCookie(CommonCookie.profile, response.data?.data?.profile_flow_screen, {
            path: "/",
            secure: true,
            sameSite: "lax",
          });
        }
        navigate("/teamsize");
      }else{
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Oops, Something went wrong.");
    }
  };

  return (
    <AuthLayout>
      <Row className="confirm">
        <Col className="col-md-1">
          <Image
            onClick={backToBusiness}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.confirmBar} />
        </Col>
        <center>
          <h2>{t("confirmAddress")}</h2>
          <h6>{t("clientFind")}</h6>
        </center>
      </Row>
      <Row className="formRow confirm">
        <Col className="col-md-12 confirmText">
          <InputText
            class={className}
            placeholder={t("streetAddress1")}
            type="text"
            value={streetAddress1}
            onChange={(e) => setstreetAddress1(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{streetAddress1Error}</small>
        </Col>
        <Col className="col-md-12 confirmText">
          <InputText
            class={className}
            placeholder={t("streetAddress2")}
            type="text"
            value={streetAddress2}
            onChange={(e) => setstreetAddress2(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{streetAddress2Error}</small>
        </Col>
      </Row>

      <Row className="formRow confirm">
        <Col className="col-md-12">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => getState(e.target.value)}
          >
            <option value="">{t("country")}</option>
            {country.map((item: any, ind: any) => (
              <option value={item.country_id} key={ind}>
                {item.country_name}
              </option>
            ))}
          </Form.Select>
          <small className="form-text text-danger">{countryError}</small>
        </Col>
      </Row>

      <Row className="formRow confirm">
        <Col className="col-md-12">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => getCity(e.target.value)}
          >
            <option value="">{t("state")}</option>
            {state.map((stateItem: any, ind: any) => (
              <option value={stateItem.id} key={ind}>
                {stateItem.state_name}
              </option>
            ))}
          </Form.Select>
          <small className="form-text text-danger">{stateError}</small>
        </Col>
      </Row>
      <Row className="formRow confirm">
        <Col className="col-md-12">
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">{t("city")}</option>
            {city.map((cityItem: any, ind: any) => (
              <option value={cityItem.id} key={ind}>
                {cityItem.city_name}
              </option>
            ))}
          </Form.Select>
          <small className="form-text text-danger">{cityError}</small>
        </Col>
      </Row>
      <Row className="formRow confirm">
        <Col className="col-md-12">
          <InputText
            class={className}
            placeholder={t("postCode")}
            type="text"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{postcodeError}</small>
        </Col>
      </Row>
      <Row className="formRow confirm">
        <Col className="col-md-6">
          <CustomButton
            class="resetButton"
            onClickButton={() => resetForm()}
            ButtonTitle={t("reset")}
          ></CustomButton>
        </Col>
        <Col className="col-md-6">
          <CustomButton
            class="confirmButton"
            ButtonTitle={t("continue")}
            onClickButton={() => addAboutInfo()}
          ></CustomButton>
        </Col>
      </Row>
    </AuthLayout>
  );
};

export default Confirm;
