import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import Alert from "react-bootstrap/Alert";
import { icons } from "../../Assets/icons";
import "./About.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useLocation, useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AuthService from "../../Services/auth.service";
import toast from "react-hot-toast";
import CommonCookie from "../../Components/CommonCookie";
import { useCookies } from "react-cookie";

const About: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [businessname, setBusinessname] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [businesstype, setBusinesstype] = useState("");
  const [businessTypeList, setBusinessTypeList] = useState<any>([]);
  const [businessTypeError, setBusinessTypeError] = useState("");
  const [extension, setExtension] = useState("+91");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  
  const [terms, setTerms] = useState("");
  const [termsError, setTermsError] = useState("");
  const [opt, setOpt] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [className, setClassName] = useState("mb-3");
  const [apiMessage, setMessage] = useState("");
  const [loginData, setLoginData] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);
  const location = useLocation();
  const authService = AuthService();
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);


  // function for go back to business screen
  function back() {
    navigate(-1);
  }
  const navRef = React.useRef(null);
  // function about us form validation and redirection on next screen after successfull validation

  useEffect(() => {
    if (location.state === null) {
      navigate("/business");
    }
    commonDropdowns();
    setLoginData(location.state?.loginData);
  }, []);

  const storeBusinessInfo = async () => {
    const fd = new FormData();
    fd.append("category_id", loginData.catId || "");
    fd.append("business_name", businessname || "");
    fd.append("user_name", name || "");
    fd.append("country_code", extension || "");
    fd.append("country_iso", "in" || "");
    fd.append("business_type", businesstype || "");
    fd.append("mobile", phone || "");
    try {
      const response = await authService().storeBusinessInfo(fd);
      toast.success(response.data.message);
      if (response.data.success) {
        getProfileData();
      }
      setMessage(response.data.success);
    } catch (error: any) {
      setErrorStatus(true);
      setMessage(error);
      toast.error(error?.data?.message || "Oops, Something went wrong.");
    }
  };

  const commonDropdowns = async () => {
    try {
      const response = await authService().commonDropdowns();
      if (response.data.success) {
        toast.success(response.data.message);
        setBusinessTypeList(response.data?.business_types);
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setErrorStatus(true);
      toast.error(error.data.message);
    }
  };

  const handleChange = (event:any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setTerms('1');
    }
  };

  function addAboutInfo() {
    setClassName(className === "mb-3" ? "" : "mb-3");
    let isValid = true;
    setBusinessNameError("");
    setNameError("");
    setBusinessTypeError("");
    setPhoneError("");
    setTermsError("");
    if (businessname == "") {
      setBusinessNameError("Please enter Your Business Name");
      isValid = false;
    }

    if (name == "") {
      setNameError("Please enter Your Name");
      isValid = false;
    }

    if (businesstype == "") {
      setBusinessTypeError("Please select Your Business");
      isValid = false;
    }

    if (phone == "") {
      setPhoneError("Please enter Your Phone Number");
      isValid = false;
    } else if (phone.search(/[0-9]/) == -1) {
      setPhoneError("Phone Number should be Numeric");
      isValid = false;
    } else if (phone.length < 8) {
      setPhoneError("Phone Number must be 8 characters");
      isValid = false;
    }
    if (!isChecked) {
      setTermsError("Please Accept Terms and Contitions");
      isValid = false;
    }

    if (isValid == true) {
      storeBusinessInfo();
    }
    // navigate('/work');
  }

  const getProfileData = async () => {
    const fd = new FormData();
    try {
      const response = await authService().getProfileData(fd);
      if (response.data.success) {
        // toast.success(response.data.message);
        setCookie(CommonCookie.vendor_id, response.data?.data?.vendorInformation[0]?.vendor_detail_id, {
          path: "/",
          secure: true,
          sameSite: "lax",
        });
        if(businesstype == '2'){
          navigate("/businesshours"); 
        }else{
          navigate("/work");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  return (
    <AuthLayout>
      <Row className="about">
        <Col className="col-md-1">
          <Image onClick={back} className="backButton" src={icons.back} />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.aboutBar} />
        </Col>
        <center>
          <h1>{t("aboutYou")}</h1>
          <h6 className="tellus">{t("tellUsMore")}</h6>
        </center>
      </Row>
      <Row className="formRow about">
        <Col className="col-md-6">
          <InputText
            class={className}
            placeholder={t("businessName")}
            type="text"
            value={businessname}
            onChange={(e) => setBusinessname(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{businessNameError}</small>
        </Col>
        <Col className="col-md-6">
          <InputText
            placeholder={t("yourName")}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{nameError}</small>
        </Col>
      </Row>
      <Row className="formRow about">
        <Col className="col-md-12">
          <Form.Select
            className={className}
            onChange={(e) => setBusinesstype(e.target.value)}
            aria-label="Default select example"
          >
            <option value="">{t("businessType")}</option>
            {businessTypeList.map((type: any, ind: any) => (
              <option value={type.id} key={ind}>
                {type.name}
              </option>
            ))}
          </Form.Select>
          <small className="form-text text-danger">{businessTypeError}</small>
        </Col>
      </Row>
      <Row className="formRow about">
        <Col className="col-md-4">
          <Form.Select
            className="extension"
            onChange={(e) => setExtension(e.target.value)}
            aria-label="Default select example"
          >
            <option value="+91">+91</option>
            <option value="+44">+44</option>
            <option value="+1">+1</option>
          </Form.Select>
        </Col>
        <Col className="col-md-8">
          <InputText
            placeholder={t("phoneNumber")}
            class={className}
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></InputText>
          <small className="form-text text-danger">{phoneError}</small>
        </Col>
      </Row>
      <Col className="col-md-12 chk">
        <Form.Check
          aria-label="option 1"
          className={className}
          checked={isChecked} onChange={handleChange}
          label={t("termsConditions")}
        />
        <small className="form-text text-danger">{termsError}</small>
      </Col>

      <Col className="col-md-12 chk">
        <Form.Check
          aria-label="option 1"
          onChange={(e) => setOpt(e.target.value)}
          label={t("glamPromotion")}
        />
      </Col>
      <Row className="formRow about">
        <CustomButton
          class="aboutButton"
          ButtonTitle={t("continue")}
          onClickButton={() => addAboutInfo()}
        ></CustomButton>
      </Row>
    </AuthLayout>
  );
};

export default About;
