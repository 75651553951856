import facebook from "./facebook.svg";
import google from "./google.svg";
import back from "./back.svg";
import check from "./check.svg";
import pwdBar from "./pwdBar.svg";
import forgotBar from "./forgotBar.svg"
import businessBar from "./businessBar.svg";
import hairSaloon from "./hairSaloon.svg";
import nailSaloon from "./nailSaloon.svg";
import eyeBrow from "./eyeBrow.svg";
import barber from "./barber.svg";
import skinCare from "./skinCare.svg";
import aestheticMedicine from "./aestheticMedicine.svg";
import aboutBar from "./aboutBar.svg";
import close from "./close.svg";
import india from "./india.svg";
import uk from "./UK.svg";
import us from "./US.svg";
import workBar from "./workBar.svg";
import addressBar from "./addressBar.svg";
import confirmBar from "./confirmBar.svg";
import workLocation from "./workLocationBar.svg";
import search from "./searchAddress.svg";
import teamBar from "./teamBar.svg";
import hoursArrow from "./hoursArrow.svg";
import serviceBar from "./serviceBar.svg";
import deleteService from "./deleteService.svg";
import helpBar from "./helpBar.svg";
import prevousBar  from "./previousBar.svg";
import calender from "./calender.svg";
import dashboard from "./dashboard.svg";
import dashboardActive from "./dashboardActive.svg" 
import salesReport from "./salesReport.svg";
import customerList from "./customerList.svg";
import contactList from "./contactList.svg";
import paymentDetails from "./paymentDetails.svg";
import teamMembers from "./teamMembers.svg";
import settings from "./settings.svg";
import notification from "./notification.svg";
import help from "./help.svg";
import userImage from "./userImage.svg";
import service from "./services.svg";
import anyDate from "./anyDate.svg";
import location from "./location.svg";
import rating from "./rating.svg";
import recommendedSearch from "./recommendedSearch.svg";
import apple from "./apple.svg";
import googledownload from "./googleDownload.svg";
import qrCode from "./qrCode.svg";
import footerFacebook from "./footerFacebook.svg";
import instagram from "./instagram.svg";
import linkedIn from "./linkedIn.svg";
import twitter from "./twitter.svg";
import left from "./left.svg";
import right from "./right.svg";
import hamgurger from "./hamburger.svg";
import upload from "./upload.svg"; 
import like from "./like.svg";
import shopLocation from "./shopLocation.svg";
import photos from "./photos.svg";
import cv from "./CandV.svg";
import time from "./time.svg";
import shopInstagram from "./shopInstagram.svg";
import shopFacebook from "./shopFacebook.svg";
import shopTwitter from "./shopTwitter.svg";
import shopGlobe from "./shopGlobe.svg";
import shopYoutube from "./shopYoutube.svg";
import profilePhoto from "./profilePhoto.svg";
import settingsUpload from "./uploadSvg.svg";
import filter from "./filter.svg";
import downArrow from "./downArrow.svg";
import serviceHelp from "./serviceHelp.svg";
import jackLion from "./jackLion.svg";
import laraRay from "./laraRay.svg";
import lorenJam from "./lorenJem.svg";
import franklinJobs from "./franklinJobs.svg";
import micheDom from "./michleDom.svg";
import jerry from "./jerry.svg";
import edit from "./edit.svg";
import teamCalender from "./teamCalender.svg";
import calenderEdit from "./calenderEdit.svg";
import timer from "./timer.svg";
import leftArrow from "./leftArrow.svg";
import xlsx from "./xls.svg";
import pdf from "./pdf.svg";
import imageFile from "./imageFile.svg";
import threeDots from "./threeDots.svg";
import updownArrows from "./upDownArrows.svg";
import downloadPDF from "./downloadPdf.svg";
import camera from "./camera.svg";
import merge from "./merge.svg";
import excel from "./excel.svg";
import logout from "./logout.svg";
import url from "./url.svg";

export const icons = {
  facebook,
  google,
  back,
  check,
  pwdBar,
  forgotBar,
  businessBar,
  hairSaloon,
  nailSaloon,
  eyeBrow,
  logout,
  barber,
  url,
  skinCare,
  aestheticMedicine,
  aboutBar,
  india,
  camera,
  merge,
  excel,
  close,
  uk,
  us,
  workBar,
  addressBar,
  search,
  confirmBar,
  workLocation,
  teamBar,
  hoursArrow,
  serviceBar,
  deleteService,
  helpBar,
  prevousBar,
  dashboard,
  dashboardActive,
  calender,
  salesReport,
  customerList,
  contactList,
  paymentDetails,
  teamMembers,
  settings,
  notification,
  help,
  userImage,
  service,
  location,
  anyDate,
  rating,
  recommendedSearch,
  apple,
  qrCode,
  googledownload,
  footerFacebook,
  instagram,
  linkedIn,
  twitter,
  left,
  leftArrow,
  right,
  hamgurger,
  upload,
  like,
  shopLocation,
  photos,
  cv,
  time,
  shopInstagram,
  shopFacebook,
  shopGlobe,
  shopTwitter,
  shopYoutube,
  profilePhoto,
  settingsUpload,
  filter,
  downArrow,
  serviceHelp,
  jackLion,
  laraRay,
  lorenJam,
  franklinJobs,
  micheDom,
  jerry,
  edit,
  calenderEdit,
  teamCalender,
  timer,
  xlsx,
  pdf,
  imageFile,
  threeDots,
  updownArrows,
  downloadPDF,
};
