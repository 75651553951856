import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row  } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Dashboard.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';

import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import CustomButton from "../../Components/CustomButton/CustomButton";



const Dashboard: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [selectedTitle, setSelectedTitle] = useState(null);
  function booked(){

  }
    
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
        < AdminMenu/>
      </Col>
      
      <Col className="col-md-10">
      < AdminHeader/>
        <Row className="dashboardRow">
          <Col className="col-md-4 chart1">
            <Image src={images.Chart1} ></Image>
          </Col>
          <Col className="col-md-4 chart2">
            <Image src={images.Chart2} ></Image>
          </Col>
          <Col className="col-md-4 appointment">
             <h3>{t("appointmentActivity")}</h3> 
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="started"> Started</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="started"> Started</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
          </Col>
          <Col className="col-md-4 todayAppointment">
             <h3>{t("todayAppointment")}</h3> 
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="started"> Started</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="started"> Started</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
             <Row>
                <Col className="col-md-1">
                  <span className="appointmentDate">
                    26
                  </span>
                  <span className="appointmentMonth">
                    July
                  </span>
                </Col>
                <Col className="col-md-5">
                  <h6>Fri 10:30am<span className="new"> New</span></h6>
                  <h5>Beard</h5>
                </Col>
                <Col className="col-md-5 customer">
                   <span>James Cardwell
                   30 min with jane</span> 
                </Col>
                <Col className="col-md-1 price">
                  <span>₹30</span> 
                </Col>
             </Row>
          </Col>
          <Col className="col-md-4 topServices">
             <h3>{t("topServices")}</h3> 
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Hair Cut
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">189 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Pedicure
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">182 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Hair Message
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">122 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Manicure
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">101 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Hair Color
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">92 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Beard
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">80 times booked</span>
                </Col>
             </Row>
             <Row>
                <Col className="col-md-6">
                  <span className="title">
                    Face Wax
                  </span>
                </Col>
                <Col className="col-md-6">
                  <span className="status">77 times booked</span>
                </Col>
             </Row>
          </Col>
          <Col className="col-md-4 topTeamMembers">
            <h3>{t("topTeamMembers")}</h3>
            <Row>
              <Col className="col-md-6 firstColumn">
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Mark} ></Image>
                    <h2>Mark</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
              
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Julia} ></Image>
                    <h2>Julia</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Sandra} ></Image>
                    <h2>Sandra</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-6">
                    <Image src={images.David} ></Image>
                    <h2>David</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-6">
                    <Image src={images.John} ></Image>
                    <h2>John</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
              </Col>

              <Col className="col-md-6 firstColumn">
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Jane} ></Image>
                    <h2>Jane</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
          
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Frank} ></Image>
                    <h2>Frank</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
          
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Melisha} ></Image>
                    <h2>Melisha</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
          
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Angela} ></Image>
                    <h2>Angela</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
          
                <Row>
                  <Col className="col-md-6">
                    <Image src={images.Dane} ></Image>
                    <h2>Dane</h2>
                    <h4>Hairdresser</h4>
                  </Col>
                  <Col className="col-md-6">
                    <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          
          </Col> 
        </Row>  
        </Col>
      </Row>  
  );
};
export default Dashboard;