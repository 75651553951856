import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Work.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate } from "react-router-dom";

import AuthLayout from "../../Components/AuthLayout.tsx/AuthLayout";
import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import { useCookies } from "react-cookie";
import CommonCookie from "../../Components/CommonCookie";

const Work: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [priority, setPriority] = useState("");
  const [work, setWork] = useState<any>("");
  const [client, setClient] = useState<any>("");
  const [priorityError, setPriorityError] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  // function for go back to about page
  function backToAbout() {
    navigate(-1);
  }

  // function for checkbox validation and move to next screen after successfull validation
  function addWorkInfo() {
    let isValid = true;
    setPriorityError("");

    if (work  == "" && client == '') {
      setPriorityError("Please Select Any One Option");
      isValid = false;
    }
    if (isValid == true) {
      const status = client && work ? "3" : client ? "2" : work ? "1" : "";
      console.log(status);
      navigate("/confirm", { state: { location: { status: status } } });
    }
  }

  return (
    <AuthLayout>
      <Row className="work">
        <Col className="col-md-1">
          <Image
            onClick={backToAbout}
            className="backButton"
            src={icons.back}
          />
        </Col>
        <Col className="col-md-11">
          <Image className="progressBar" src={icons.workBar} />
        </Col>
        <center>
          <h2>{t("whereWork")}</h2>
          <h6>{t("clientVisit")}</h6>
        </center>
      </Row>
      <Row className="formRow mb-3 work">
        <Col className="col-md-12">
          <Form.Check
            aria-label="option 1"
            value="1"
            onChange={(e) => {
              setPriority(e.target.value);
              setWork(e.target.checked);
            }}
            label={t("myPlace")}
          />
          <p>{t("myPlace1")}</p>
        </Col>
      </Row>

      <Row className="formRow mb-3 work">
        <Col className="col-md-12">
          <Form.Check
            aria-label="option 1"
            value="2"
            onChange={(e) => {
              setPriority(e.target.value);
              setClient(e.target.checked);
            }}
            label={t("clientPlace")}
          />
          <p>{t("clientPlace1")}</p>
        </Col>
      </Row>

      <Row className="formRow mb-3 work">
        <small className="form-text text-danger mb-3">{priorityError}</small>
        <CustomButton
          class="workButton"
          ButtonTitle={t("continue")}
          onClickButton={() => addWorkInfo()}
        ></CustomButton>
      </Row>
    </AuthLayout>
  );
};

export default Work;
