import { API_URLS } from "../core/apis/apis";
import useAxiosPrivate from "../core/hooks/useAxiosPrivate";
import useAxios from "../core/hooks/useAxios";

const AuthService = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();
  // Define and return authentication service functions
  const services = () => {
    return {
      login: async (data: any) => {
        return await axios.post(`${API_URLS.auth.login}`, data);
      },
      register: async (data: any) => {
        return await axios.post(`${API_URLS.auth.vendor_registration}`, data);
      },
      checkUser: async (data: any) => {
        return await axios.post(`${API_URLS.auth.check_user}`, data);
      },
      forgotPassword: async (data: any) => {
        return await axios.post(
          `${API_URLS.auth.vendor_forgot_password}`,
          data
        );
      },
      commonDropdowns: async () => {
        return await axios.get(`${API_URLS.common.common_dropdown}`);
      },
      teamInfo: async () => {
        return await axios.get(`${API_URLS.common.team_info}`);
      },
      helpInfo: async () => {
        return await axios.get(`${API_URLS.common.get_help_information}`);
      },
      getCategories: async () => {
        return await axios.get(`${API_URLS.common.get_categories}`);
      },
      prevToolsInfo: async () => {
        return await axios.get(`${API_URLS.common.get_previous_tools}`);
      },
      getScheduleDays: async () => {
        return await axios.get(`${API_URLS.common.get_schedule_days}`);
      },
      getServiceType: async (catId: any) => {
        const fd = new FormData();
        fd.append("category_id", catId);
        return await axios.post(`${API_URLS.common.get_service_type}`, fd);
      },
      getServiceList: async () => {
        const fd = new FormData();
        // fd.append("category_id", catId);
        return await axiosPrivate.post(`${API_URLS.category.get_services}`, fd);
      },
      defaultService: async (vendorId: any) => {
        const fd = new FormData();
        // fd.append("category_id", catId);
        return await axiosPrivate.post(`${API_URLS.category.default_service}`, fd);
      },
      getServiceCat: async () => {
        return await axios.get(`${API_URLS.common.get_service_cat}`);
      },
      getLocation: async (countryId?: any, stateId?: any) => {
        countryId = countryId === undefined ? "" : countryId;
        return await axios.get(
          `${API_URLS.common.get_location + "?"}${
            countryId ? "countryId=" + countryId : ""
          }${stateId ? "&stateId=" + stateId : ""}`
        );
      },
      storeBusinessInfo: async (data: any) => {
        return await axiosPrivate.post(
          `${API_URLS.business_info.store_business_info}`,
          data
        );
      },
      getProfileData: async (data: any) => {
        return await axiosPrivate.post(
          `${API_URLS.auth.get_profile}`,
          data
        );
      },
      storeBusinessAddress: async (data: any) => {
        return await axiosPrivate.post(
          `${API_URLS.business_info.store_business_address}`,
          data
        );
      },
      storeSchedule: async (data: any) => {
        return await axiosPrivate.post(
          `${API_URLS.business_schedule.store_schedule}`,
          data
        );
      },
      deleteService: async (id: any) => {
        return await axiosPrivate.delete(
          `${API_URLS.category.remove_service}${id ? "/" + id : ""}`
        );
      },
      addService: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.category.store_services}`, data);
      },
      addToolsHelpInfo: async (data: any) => {
        return await axiosPrivate.post(`${API_URLS.business_info.store_tool_help_info}`, data);
      },
    };
  };
  return services;
};

export default AuthService;
