import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Nav, Row, Modal  } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import "bootstrap/dist/css/bootstrap.min.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { images } from "../../Assets/images";
import { icons } from "../../Assets/icons";
import "./Customerdetail.css";
import { changeLanguage, useAppTranslation } from "../../Utils/i18n";
import { useNavigate, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDropzone } from 'react-dropzone';


import CustomButton from "../../Components/CustomButton/CustomButton";
import InputText from "../../Components/CustomInputText/InputText";
import AdminMenu from "../../Components/AdminMenu/AdminMenu";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";


  const Customerdetail: React.FC = () => {
  const t = useAppTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [key, setKey] = useState('home');
  const [selectedImage, setSelectedImage] = useState(null);

  /* business hours */


  
  const [webError, setWebError] = useState("");

  function addHours(){
    navigate('/service');
  // }
}

/* business hours */
  
  
  const handleTitleClick = (title:any) => {
    setSelectedTitle(title);
  };

  
  function gotoAbout(){

  }
  function booked(){

  }
    
  return (
    <Row className="dashboard">
      <Col className="col-md-2">
      <AdminMenu />
      </Col>
      <Col className="col-md-10">
        <Row className="header">
        <AdminHeader />
        </Row>
        <Row className="dashboardRow customerDetail">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} className="left">
        <Row>
          <div className="col-md-3"><Image className="leftArrow" src={icons.leftArrow}></Image></div>
          <div className="col-md-6">
            <center><Image className="profilePhoto" src={icons.micheDom}></Image>
            <h4>Michle dom</h4>
            <h5>michled@email.com</h5></center>
          </div>
          <div className="col-md-3"><Image className="edit" src={icons.edit}></Image></div>
          </Row>
        <CustomButton class="bookButton" ButtonTitle={t("bookNow")} onClickButton={() => booked()} ></CustomButton>
              <ButtonGroup>
              <DropdownButton className="options" as={ButtonGroup} title={t("actions")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          <Nav variant="pills" className="flex-column categories">
            <Nav.Item>
              <Nav.Link eventKey="first">{t("appointments")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">{t("sales")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nail">{t("customerDetails")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="wax">{t("files")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="eye">{t("reviews")}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} className="right">
          <Tab.Content className="tabContent">
            <Tab.Pane eventKey="first">
              <Row>
                <div className="col-md-6"><h5 className="top">Appointments</h5></div>
                <div className="col-md-6">
              <ButtonGroup className="completed">
              <DropdownButton as={ButtonGroup} title={t("completed")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
            </div>
            </Row>
                <Row className="serviceItems main">
                  <Col className="col-md-3">
                    <h3>Appointment</h3>
                    <h4>Mon 29 Jul 11:00am <span>Booked</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h3>Hair Cut</h3>
                    <h4>11:00am - 30min <span>John Doe</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h6>₹30.00</h6>
                  </Col>
                  <Col className="col-md-3">
                  <CustomButton class="checkoutButton" ButtonTitle={t("checkout")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row> 

                <Row className="serviceItems main">
                  <Col className="col-md-3">
                    <h3>Appointment</h3>
                    <h4>Mon 29 Jul 11:00am <span>Booked</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h3>Hair Cut</h3>
                    <h4>11:00am - 30min <span>John Doe</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h6>₹30.00</h6>
                  </Col>
                  <Col className="col-md-3">
                  <CustomButton class="checkoutButton" ButtonTitle={t("checkout")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row> 

                <Row className="serviceItems main">
                  <Col className="col-md-3">
                    <h3>Appointment</h3>
                    <h4>Mon 29 Jul 11:00am <span>Booked</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h3>Hair Cut</h3>
                    <h4>11:00am - 30min <span>John Doe</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h6>₹30.00</h6>
                  </Col>
                  <Col className="col-md-3">
                  <CustomButton class="checkoutButton" ButtonTitle={t("checkout")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row> 

                <Row className="serviceItems main">
                  <Col className="col-md-3">
                    <h3>Appointment</h3>
                    <h4>Mon 29 Jul 11:00am <span>Booked</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h3>Hair Cut</h3>
                    <h4>11:00am - 30min <span>John Doe</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h6>₹30.00</h6>
                  </Col>
                  <Col className="col-md-3">
                  <CustomButton class="checkoutButton" ButtonTitle={t("checkout")} onClickButton={() => booked()} ></CustomButton>
                  </Col>
                </Row> 
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <h5 className="top">{t("sales")}<ButtonGroup className="completed">
              <DropdownButton as={ButtonGroup} title={t("all")} id="bg-nested-dropdown">
                <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup></h5>
            
             

            <Row className="serviceItems sales">
            <Col className="col-md-6 main">
                <Row>
                  <Col className="col-md-6">
                    <h3>Face Wax</h3>
                    <h4>28 July 2024 <span>Jhone Doe</span></h4>
                  </Col>
                  <Col className="col-md-6">
                    <h6>₹45</h6>
                    <p>Cash</p>
                  </Col>
                </Row>
              </Col>
              
              <Col className="col-md-6 main">
                <Row>
                  <Col className="col-md-6">
                    <h3>Beard</h3>
                    <h4>30 July 2024 <span>Jhone Doe</span></h4>
                  </Col>
                  <Col className="col-md-6">
                    <h6>₹25</h6>
                    <p>Cash</p>
                  </Col>
                </Row>
              </Col>
            </Row> 


              </Tab.Pane>
            <Tab.Pane eventKey="nail">
            <h5 className="top">{t("customerDetails")} <span>Joined 29 July 2024</span></h5> 
              <Row className="serviceItems">
                <Col className="col-md-12 ">
                  <Row>
                     <h2>{t("profileDetails")}</h2> 
                      <Col className="col-md-6">
                        <h5>{t("fullName")}: <span>Michle Dom</span></h5>
                      </Col>
                      <Col className="col-md-6">
                        <h5>{t("dob")}: <span>11 Jan 1994</span></h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-md-6">
                        <h5>{t("phoneNumber")}: <span>Michle Dom</span></h5>
                      </Col>
                      <Col className="col-md-6">
                        <h5>{t("gender")}: <span>male</span></h5>
                      </Col>
                    </Row>

                    <Row>
                  <Col className="col-md-6">
                    <h5>{t("pronious")} : <span>michled@gmail.com</span></h5>
                  </Col>
                  <Col className="col-md-6">
                    <h5>Pronious: <span>Not Specified</span></h5>
                  </Col>
                </Row>
                </Col>
              </Row> 
              <hr></hr>

              <Row className="serviceItems">
                <Col className="col-md-12 ">
                  <Row>
                     <h2>{t("additionalInfo")}</h2> 
                      <Col className="col-md-4">
                        <h5>{t("occupation")}: <span>None</span></h5>
                      </Col>
                      <Col className="col-md-4">
                        <h5>{t("clientSource")}: <span>None</span></h5>
                      </Col>
                      <Col className="col-md-4">
                        <h5>{t("country")}: <span>None</span></h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="col-md-4">
                        <h5>{t("additionalPhone")}: <span>None</span></h5>
                      </Col>
                      <Col className="col-md-4">
                        <h5>{t("prefferedLanguage")}: <span>None</span></h5>
                      </Col>
                      <Col className="col-md-4">
                        <h5>{t("additionalEmail")}: <span>None</span></h5>
                      </Col>
                    </Row>
                </Col>
              </Row> 
              <hr></hr>
              
              <Row className="serviceItems">
                <Col className="col-md-12 ">
                  <Row>
                     <h2>{t("address")}</h2> 
                      <Col className="col-md-4">
                        <h5><span>{t("noAddress")}</span></h5>
                      </Col>
                    </Row>
                </Col>
              </Row> 
              <hr></hr>

            </Tab.Pane>
            <Tab.Pane eventKey="wax">
            <Row className="col-md-12">
            <div className="col-md-6">
              <h3>{t("files")}</h3>
              <InputText placeholder={t("search")} type="text" value={search} onChange={(e) => setSearch(e.target.value)} ></InputText>    
            </div>
            <div className="col-md-6">
            
            
            
            
            
            <Button className="filterButton">Filters <Image src={icons.filter} ></Image></Button>
            <Button className="addButton"> Add </Button>
            </div>
          </Row>  
              <Row className="serviceItems">
            <Col className="col-md-6 main file">
                <Row>
                <Col className="col-md-3">
                    <Image src={icons.xlsx}></Image>
                  </Col>
                  <Col className="col-md-6">
                    <h3>Loremipsum file.xlsx</h3>
                    <h4>29 Jul 2024 <span>xlsx</span></h4>
                  </Col>
                  <Col className="col-md-3">
                    <h6><Image src={icons.threeDots}></Image></h6>
                  </Col>
                </Row>
              </Col>
              
              <Col className="col-md-6 main file">
                <Row>
                  <Col className="col-md-3">
                    <Image src={icons.pdf}></Image>
                  </Col>
                  <Col className="col-md-6">
                    <h3>Loremipsum file.pdf</h3>
                    <h4>29 Jul 2024<span>pdf</span></h4>
                  </Col>
                  <Col className="col-md-3">
                  <h6><Image src={icons.threeDots}></Image></h6>
                  </Col>
                </Row>
              </Col>

              <Col className="col-md-6 main file">
                <Row>
                  <Col className="col-md-3">
                    <Image src={icons.imageFile}></Image>
                  </Col>
                  <Col className="col-md-6">
                    <h3>Imagefile.jpeg</h3>
                    <h4>29 Jul 2024<span>jpeg</span></h4>
                  </Col>
                  <Col className="col-md-3">
                  <h6><Image src={icons.threeDots}></Image></h6>
                  </Col>
                </Row>
              </Col>
            </Row> 

            </Tab.Pane>
            <Tab.Pane eventKey="eye">
              <h5 className="top">{t("reviews")}</h5>
              <Row className="serviceItems">
                <Col className="col-md-6 main review">
                  <Row>
                    <Col className="col-md-6">
                      <h3>Hair Cut</h3>
                    </Col>
                    <Col className="col-md-6">
                      <h6><h4>29 Jul 2024</h4></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      <div className="rating">
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col className="col-md-6 main review">
                  <Row>
                    <Col className="col-md-6">
                      <h3>Face Wash</h3>
                    </Col>
                    <Col className="col-md-6">
                      <h6><h4>29 Jul 2024</h4></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      <div className="rating">
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col className="col-md-6 main review">
                  <Row>
                    <Col className="col-md-6">
                      <h3>Hair Color</h3>
                    </Col>
                    <Col className="col-md-6">
                      <h6><h4>29 Jul 2024</h4></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      <div className="rating">
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col className="col-md-6 main review">
                  <Row>
                    <Col className="col-md-6">
                      <h3>Beard</h3>
                    </Col>
                    <Col className="col-md-6">
                      <h6><h4>29 Jul 2024</h4></h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-12">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      <div className="rating">
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                        <Image src={icons.rating} ></Image>
                      </div>
                    </Col>
                  </Row>
                </Col>
              
              
            </Row> 
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
          
        </Row>  
        </Col>
      </Row>  
  );
};

export default Customerdetail;